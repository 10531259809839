import React, {Component, Fragment} from 'react';
import {objectOf, any, func} from 'prop-types';

import './category.css';
// import Item from './Item/item';

class Category extends Component {
    state = {
        showCategoryContent: false
    };

    handleCategoryClick = () => {
        const {showCategoryContent} = this.state;
        this.setState({showCategoryContent: !showCategoryContent});
    };

    render() {
        const {category, onDragItem} = this.props;
        const {showCategoryContent} = this.state;

        return (
            <li className={`sketch-category ${showCategoryContent ? `sketch-category-active` : ``}`}>
                <p className="sketch-category-title flex-center space-between pointer " onClick={this.handleCategoryClick}>
                    {category.title} <i className={`fal fa-angle-${showCategoryContent ? `up` : `down`}`} />
                </p>
                {showCategoryContent && (
                    <Fragment>
                        {category.items && (
                            <ul className="items-list sketch-item-list">
                                {category.items.map(item => (
                                    <li
                                        className="sketch-item"
                                        data-item-height={item.length}
                                        data-item-width={item.width}
                                        draggable
                                        item_id={item._id}
                                        key={item._id}
                                        onDragStart={onDragItem}>
                                        <div className="sketch-item-image">
                                            <img alt="" className="contain" src={item.image} />
                                        </div>
                                        <p className="item-title">{item.title}</p>
                                        <p className="item-desc">{item.description}</p>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {category.subcategories &&
                            category.subcategories.map(subCategory => (
                                <ul className="sketch-sub-category" key={subCategory._id}>
                                    <Category category={subCategory} onDragItem={onDragItem} />
                                </ul>
                            ))}
                    </Fragment>
                )}
            </li>
        );
    }
}

Category.propTypes = {
    category: objectOf(any).isRequired,
    onDragItem: func.isRequired
};

export default Category;
