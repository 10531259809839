import React from 'react';
import {string, number} from 'prop-types';
import './recentSketch.css';

const RecentSketch = ({title, scale}) => {
    return (
        <li className="recent-sketch pointer">
            <p>{title}</p>
            <p>1:{scale}</p>
        </li>
    );
};

RecentSketch.propTypes = {
    title: string.isRequired,
    scale: number.isRequired
};

export default RecentSketch;
