import React from 'react';
import {func, string} from 'prop-types';
import './index.css';

const Button = ({title, onClick}) => {
    return (
        <button className="phisher-button pointer" onClick={onClick} type="submit">
            {title}
        </button>
    );
};

Button.propTypes = {
    title: string.isRequired,
    onClick: func
};

Button.defaultProps = {
    onClick: () => false
};

export default Button;
