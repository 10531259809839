import React, {Component} from 'react';
import './newSketch.css';
import {withRouter} from 'react-router-dom';
import {objectOf, any, arrayOf} from 'prop-types';
import {Consumer} from '../../contexts/sketchContext';
import {create_sketch} from '../../lib/joossens_client';
import {handleHideModalAnywhere} from '../../lib/helpers';

import RecentSketch from './RecentSketch/recentSketch';

import Input from '../../components/Input';
import ActionButton from '../../components/ActionButton';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';

class NewSketch extends Component {
    state = {
        scale: 20,
        paper: `A3`
    };

    createSketch = handleSetSketch => {
        const {title, scale, project_name, installer, address, ordernr, customer, paper} = this.state;
        create_sketch(paper, title, project_name, installer, address, ordernr, customer, scale, ({message, sketch}) => {
            if (message === `Success`) {
                handleSetSketch(sketch);
                this.props.history.push(`/sketch`);
            }
        });
    };

    onChange = ({target: {value, name}}) => {
        console.log([name], value);
        this.setState({[name]: value});
    };

    render() {
        const {environment, sketches} = this.props;
        const {paper} = this.state;

        return (
            <Consumer>
                {({handleSetSketch}) => (
                    <div
                        className="new-sketch-container"
                        onClick={e => handleHideModalAnywhere(e, `showNewSketchModal`, this.modalContainer, environment)}
                        ref={node => (this.modalContainer = node)}>
                        <div className="new-sketch modal-content">
                            <div className="create-sketch">
                                <div className="configurator-titles">
                                    <h1 className="upper">joossens</h1>
                                </div>
                                <div className="templates">
                                    <h2 className="templates-title">Sketch</h2>
                                </div>
                                <Select name="paper" onChange={this.onChange} title="Papier grootte" value={paper}>
                                    <option value="A3">A3</option>
                                    <option value="A4">A4</option>
                                </Select>
                                <Input name="title" onChange={this.onChange} title="Sketch title" />
                                <div className="flex-center space-between" style={{width: `100%`}}>
                                    {/* <Input name="width" onChange={this.onChange} rowSpan="triple" title="Breedte (in mm)" />
                                    <Input name="height" onChange={this.onChange} rowSpan="triple" title="Hoogte (in mm)" /> */}
                                    <Select name="scale" onChange={this.onChange} rowSpan="triple">
                                        <option value="20">1:20</option>
                                        <option value="50">1:50</option>
                                    </Select>
                                </div>
                                <div className="divide-line" />
                                <div className="flex-center space-between" style={{width: `100%`}}>
                                    <Textarea name="project_name" onChange={this.onChange} rowSpan="double" title="Project" />
                                    <Textarea name="customer" onChange={this.onChange} rowSpan="double" title="Klant" />
                                </div>
                                <Textarea name="installer" onChange={this.onChange} title="Installateur" />
                                <div className="flex-center space-between" style={{width: `100%`}}>
                                    <Input name="address" onChange={this.onChange} rowSpan="double" title="Adres" />
                                    <Input name="ordernr" onChange={this.onChange} rowSpan="double" title="Order nummer" />
                                </div>
                                <div className="templates-button pointer">
                                    <ActionButton onClick={() => this.createSketch(handleSetSketch)} text="Aanmaken" />
                                </div>
                            </div>
                            <div className="recent-sketches">
                                <h2>Recent sketches</h2>
                                <ul className="recent-sketches-list">
                                    {sketches.slice(0, 4).map(sketch => (
                                        <RecentSketch key={sketch._id} scale={sketch.scale} title={sketch.title} />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </Consumer>
        );
    }
}

NewSketch.propTypes = {
    environment: objectOf(any).isRequired,
    sketches: arrayOf(any).isRequired,
    history: objectOf(any).isRequired
};

export default withRouter(NewSketch);
