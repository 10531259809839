import React, {Component} from 'react';
import '../Library/library.css';

import ActionButton from '../../components/ActionButton';
import Category from './Category/category';
import ItemModal from './ItemModal/itemModal';
import CategoryModal from './CategoryModal/categoryModal';

import {handleShowModal} from '../../lib/helpers';
import {fetch_categories, fetch_library, delete_category, delete_item} from '../../lib/joossens_client';

class Library extends Component {
    state = {
        showItemModal: false,
        showCategoryModal: false,
        categories: [],
        library: []
    };

    componentDidMount() {
        this.fetchCategories();
        this.fetchLibrary();
    }

    fetchLibrary = () => fetch_library(({message, categories: library}) => message === `Success` && this.setState({library}));

    fetchCategories = () => fetch_categories(({message, categories}) => message === `Success` && this.setState({categories}));

    findCategory = (id, categories) => {
        const findCategoryInSubcategories = ({subcategories}) => (subcategories ? this.findCategory(id, subcategories) : null);

        if (categories) {
            for (const category of categories) {
                if (category._id === id) {
                    return category;
                } else {
                    const foundInSubcategories = findCategoryInSubcategories(category);
                    if (foundInSubcategories) return foundInSubcategories;
                }
            }
            return null;
        } else return null;
    };

    deleteCategory = id => {
        delete_category(id, ({message}) => {
            if (message === `Success`) {
                this.fetchCategories();
                this.fetchLibrary();
            }
        });
    };

    deleteItem = id => delete_item(id, ({message}) => message === `Success` && this.fetchLibrary());

    render() {
        const {categories, showItemModal, showCategoryModal, library} = this.state;

        return (
            <div className="dashboard-container">
                <div className="flex-center space-between">
                    <h2 className="dashboard-title">Library</h2>
                    <div className="flex-center library-actions">
                        <ActionButton
                            icon={<i className="fal fa-plus" />}
                            onClick={() => handleShowModal(`showItemModal`, this)}
                            text="Nieuw item"
                        />
                        <ActionButton
                            icon={<i className="fal fa-plus" />}
                            onClick={() => handleShowModal(`showCategoryModal`, this)}
                            text="Nieuwe categorie"
                        />
                    </div>
                </div>
                <div className="library-container">
                    {library.map(category => (
                        <Category
                            category={category}
                            deleteCategory={this.deleteCategory}
                            deleteItem={this.deleteItem}
                            fetchCategories={this.fetchCategories}
                            fetchLibrary={this.fetchLibrary}
                            key={category._id}
                            updateCategory={this.updateCategory}
                        />
                    ))}
                </div>
                {showItemModal && (
                    <ItemModal
                        categories={categories}
                        environment={this}
                        fetchCategories={this.fetchCategories}
                        fetchLibrary={this.fetchLibrary}
                    />
                )}
                {showCategoryModal && (
                    <CategoryModal
                        categories={categories}
                        environment={this}
                        fetchCategories={this.fetchCategories}
                        fetchLibrary={this.fetchLibrary}
                    />
                )}
            </div>
        );
    }
}

export default Library;
