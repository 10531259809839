import React from 'react';
import {func, string, node,oneOfType,any} from 'prop-types';
import './tool.css';

const Tool = ({selectedTool, handleToolClick, name, icon,extraInfo}) => {
    return (
        <div className={`sketch-tool pointer${selectedTool === name ? ` selected-tool` : ``}`} onClick={handleToolClick}>
            {icon}
            {extraInfo && <span className="sketch-tool-extra">{extraInfo}</span>}
        </div>
    );
};

Tool.propTypes = {
    selectedTool: string.isRequired,
    name: string.isRequired,
    handleToolClick: func.isRequired,
    icon: node,
    extraInfo: oneOfType([any])
};

Tool.defaultProps = {
    icon: ``,
    extraInfo: null
};

export default Tool;
