import React, {Component, createContext} from 'react';
import {node, objectOf, any} from 'prop-types';
import {withRouter} from 'react-router-dom';
import {validate} from '../lib/joossens_client';
export const {Provider, Consumer} = createContext();

class SketchProvider extends Component {
    state = {
        selectedSketch: null,
        loggedIn: false,
        fetched: true,
        user: null
    };

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser = cb => validate(({user}) => this.setState({user, fetched: true}, () => cb && cb()));

    handleSetSketch = selectedSketch => {
        localStorage.setItem(`sketch`, JSON.stringify(selectedSketch));
        this.setState({selectedSketch});
        this.props.history.push(`/sketch`);
    };

    render() {
        return (
            <Provider
                value={{
                    ...this.state,
                    handleSetSketch: this.handleSetSketch,
                    fetchUser: this.fetchUser
                }}>
                {this.props.children}
            </Provider>
        );
    }
}

SketchProvider.propTypes = {
    children: node.isRequired,
    history: objectOf(any).isRequired
};

export default withRouter(SketchProvider);
