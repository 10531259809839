import React, {Component} from 'react';
import {
    fetch_sketches,
    delete_sketch,
    fetch_folders,
    fetch_folder,
    delete_folder,
    search_sketches
} from '../../lib/joossens_client';
import {Consumer} from '../../contexts/sketchContext';
import {handleShowModal} from '../../lib/helpers';

import NewSketch from '../NewSketch/newSketch';
import NewFolder from './Folder/NewFolder';
import Sketch from './Sketch/sketch';
import Folder from './Folder';

import ActionButton from '../../components/ActionButton';
import Search from '../../components/Search';

import './dashboard.css';

class Dashboard extends Component {
    state = {
        showNewSketchModal: false,
        showNewFolderModal: false,
        isSearching: false,
        sketches: [],
        children: [],
        currentFolder: null,
        parentFolders: []
    };
    componentDidMount() {
        this.fetchSketches();
        this.fetchFolders();
    }

    fetchSketches = () => fetch_sketches(null, null, ({message, sketches}) => message === `Success` && this.setState({sketches}));

    fetchFolders = cb =>
        fetch_folders(({message, folders}) => {
            if (message === `Success` && folders.length > 0) {
                const currentFolder = folders[0];
                const {children, sketches} = currentFolder;
                this.setState({currentFolder, children, sketches}, () => cb && cb());
            }
        });

    refreshFolder = cb => {
        const {parentFolders, currentFolder} = this.state;
        if (parentFolders.length > 0) {
            fetch_folder(currentFolder._id, ({message, folders}) => {
                if (message === `Success` && folders.length > 0) {
                    const currentFolder = folders[0];
                    const {children, sketches} = currentFolder;
                    this.setState({currentFolder, children, sketches}, () => {
                        if (children.length === 0 && sketches.length === 0) this.goUp();
                        if (cb) cb();
                    });
                }
            });
        } else this.fetchFolders(cb);
    };

    fetchFolder = (id, cb) =>
        fetch_folder(id, ({message, folders}) => {
            if (message === `Success` && folders.length > 0) {
                const currentFolder = folders[0];
                const {children, sketches} = currentFolder;
                this.setState({currentFolder, children, sketches}, () => cb && cb());
            }
        });

    deleteSketch = id => {
        delete_sketch(id, ({message}) => {
            if (message === `Success`) {
                const {sketches} = this.state;
                const filteredSketches = sketches.filter(sketch => sketch._id !== id);
                this.setState({sketches: filteredSketches});
            }
        });
    };

    lock = false;
    openFolder = (folder, reset) => {
        if (!this.lock) {
            this.lock = true;
            let {currentFolder, parentFolders} = this.state;
            parentFolders = parentFolders.concat(currentFolder);
            if (reset) {
                parentFolders = parentFolders.slice(0, parentFolders.findIndex(p => p._id === folder._id));
            }
            this.setState({parentFolders, currentFolder: folder}, () => this.fetchFolder(folder._id, () => (this.lock = false)));
        }
    };

    deleteFolder = folder => delete_folder(folder._id, ({message}) => message === `Success` && this.refreshFolder());

    goUp = () => {
        const {parentFolders} = this.state;
        if (!this.lock) {
            this.lock = true;
            const parent = parentFolders.pop();
            if (parent) this.fetchFolder(parent._id, () => (this.lock = false));
            else this.fetchFolders(() => (this.lock = false));
        }
    };

    handleSearchSketches = ({target: {value}}) => {
        search_sketches(value, ({message, sketches}) => message === `Success` && this.setState({sketches}));
        if (value === ``) {
            this.fetchSketches();
            this.setState({isSearching: false});
        } else {
            this.setState({isSearching: true});
        }
    };

    render() {
        const {
            showNewSketchModal,
            showNewFolderModal,
            sketches,
            children,
            currentFolder,
            parentFolders,
            isSearching
        } = this.state;

        return (
            <Consumer>
                {({handleSetSketch}) => (
                    <div className="dashboard-container">
                        <div className="folder-breadcrumbs">
                            {currentFolder && (
                                <ul>
                                    {parentFolders.concat(currentFolder).map(folder => (
                                        <li id={folder._id} key={folder._id}>
                                            /
                                            {folder._id === currentFolder._id ? (
                                                <span>{folder.name}</span>
                                            ) : (
                                                <span className="clickable" onClick={() => this.openFolder(folder, true)}>
                                                    {folder.name}
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {showNewSketchModal && (
                            <NewSketch environment={this} handleSetSketch={handleSetSketch} sketches={sketches} />
                        )}
                        {showNewFolderModal && (
                            <NewFolder environment={this} parent={currentFolder} updateFolders={this.refreshFolder} />
                        )}
                        <div className="flex-center space-between">
                            <div className="flex-center">
                                <h2 className="dashboard-title">sketches</h2>
                                <div style={{marginLeft: `3rem`, minWidth: `35rem`}}>
                                    <Search onChange={this.handleSearchSketches} />
                                </div>
                            </div>
                            <div className="flex-center">
                                <ActionButton
                                    icon={<i className="fal fa-plus" />}
                                    onClick={() => handleShowModal(`showNewFolderModal`, this)}
                                    text="Nieuwe Map"
                                />

                                <ActionButton
                                    icon={<i className="fal fa-plus" />}
                                    onClick={() => handleShowModal(`showNewSketchModal`, this)}
                                    text="Nieuwe Sketch"
                                />
                            </div>
                        </div>
                        <div>
                            {currentFolder &&
                                currentFolder.name !== `root` && (
                                    <div>
                                        <ActionButton
                                            icon={<i className="fal fa-angle-left" />}
                                            onClick={this.goUp}
                                            text="Terug"
                                        />
                                    </div>
                                )}
                            <ul className="flex-center sketch-list">
                                {!isSearching &&
                                    children.map(folder => (
                                        <Folder
                                            deleteFolder={this.deleteFolder}
                                            folder={folder}
                                            key={folder._id}
                                            openFolder={this.openFolder}
                                        />
                                    ))}
                                {sketches.map(sketch => (
                                    <Sketch
                                        deleteSketch={this.deleteSketch}
                                        handleSetSketch={handleSetSketch}
                                        key={sketch._id}
                                        sketch={sketch}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </Consumer>
        );
    }
}

export default Dashboard;
