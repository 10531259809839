import React, {Component} from 'react';
import './navBar.css';
import {string} from 'prop-types';
import {Link} from 'react-router-dom';

import {logout} from '../../lib/joossens_client';

class NavBar extends Component {
    state = {
        activeNav: this.props.pathname !== `/` ? this.props.pathname.split(`/`).pop() : `home`
    };

    handleNavClick = activeNav => this.setState({activeNav});

    render() {
        const {activeNav} = this.state;

        return (
            <aside className="dashboard-aside">
                <ul className="dashboard-nav">
                    <li
                        className={`dashboard-nav-item pointer ${activeNav === `home` ? `dashboard-nav-item-active` : ``}`}
                        onClick={() => this.handleNavClick(`home`)}>
                        <Link to="/">
                            <i className="fal fa-home" />
                        </Link>
                    </li>
                    <li
                        className={`dashboard-nav-item pointer ${activeNav === `library` ? `dashboard-nav-item-active` : ``}`}
                        onClick={() => this.handleNavClick(`library`)}>
                        <Link to="/library">
                            <i className="fal fa-book" />
                        </Link>
                    </li>
                    <li
                        className={`dashboard-nav-item pointer ${activeNav === `sketches` ? `dashboard-nav-item-active` : ``}`}
                        onClick={() => this.handleNavClick(`sketches`)}>
                        <Link to="/">
                            <i className="fal fa-pencil" />
                        </Link>
                    </li>
                    <li
                        className={`dashboard-nav-item pointer ${
                            activeNav === `user-settings` ? `dashboard-nav-item-active` : ``
                        }`}
                        onClick={() => this.handleNavClick(`user-settings`)}>
                        <Link to="/settings">
                            <i className="fal fa-users-cog" />
                        </Link>
                    </li>
                    <li
                        className={`dashboard-nav-item pointer ${activeNav === `settings` ? `dashboard-nav-item-active` : ``}`}
                        onClick={() => this.handleNavClick(`settings`)}>
                        <Link to="/settings">
                            <i className="fal fa-cog" />
                        </Link>
                    </li>
                    <li className="dashboard-nav-item pointer ">
                        <Link to="/login">
                            <i className="fal fa-sign-out" onClick={logout} />
                        </Link>
                    </li>
                </ul>
            </aside>
        );
    }
}

NavBar.propTypes = {
    pathname: string.isRequired
};

export default NavBar;
