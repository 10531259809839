import React, {Component, Fragment} from 'react';
import {objectOf, any, func} from 'prop-types';
import './category.css';
import Item from '../Item/item';
import ContextMenu from '../../../components/ContextMenu';
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';
import {handleShowModal} from '../../../lib/helpers';
import CategoryModal from '../CategoryModal/categoryModal';

class Category extends Component {
    state = {
        showCategoryContent: false,
        showCategoryModal: false,
        showCategoryContextMenu: false
    };

    handleCategoryClick = () => {
        const {showCategoryContent} = this.state;
        this.setState({showCategoryContent: !showCategoryContent});
    };

    handleShowCategoryContextMenu = e => {
        e.preventDefault();
        const {showCategoryContextMenu} = this.state;

        this.setState({showCategoryContextMenu: !showCategoryContextMenu, position: {x: e.pageX, y: e.pageY}});
    };

    handleShowItemContextMenu = e => {
        e.preventDefault();
        const {showItemContextMenu} = this.state;

        this.setState({
            showItemContextMenu: !showItemContextMenu,
            position: {x: e.pageX, y: e.pageY},
            itemId: e.currentTarget.id
        });
    };

    render() {
        const {category, deleteCategory, deleteItem, fetchCategories, fetchLibrary} = this.props;
        const {
            showCategoryContent,
            showCategoryContextMenu,
            showItemContextMenu,
            position,
            itemId,
            showCategoryModal
        } = this.state;

        return (
            <div className="category">
                <ContextMenu
                    position={position}
                    show={showCategoryContextMenu}
                    showContextMenu={this.handleShowCategoryContextMenu}>
                    <ContextMenuItem
                        icon={<i className="fal fa-pencil" />}
                        onClick={() => handleShowModal(`showCategoryModal`, this)}
                        title="Pas aan"
                    />
                    <ContextMenuItem
                        icon={<i className="fal fa-trash-alt" />}
                        onClick={() => deleteCategory(category._id)}
                        title="Verwijder"
                    />
                </ContextMenu>
                <ContextMenu position={position} show={showItemContextMenu} showContextMenu={this.handleShowItemContextMenu}>
                    <ContextMenuItem icon={<i className="fal fa-pencil" />} title="Pas aan" />
                    <ContextMenuItem
                        icon={<i className="fal fa-trash-alt" />}
                        onClick={() => deleteItem(itemId)}
                        title="Verwijder"
                    />
                </ContextMenu>
                <div
                    className={`category-open-btn flex-center pointer ${showCategoryContent ? `category-open-btn-active` : ``}`}
                    onClick={this.handleCategoryClick}
                    onContextMenu={this.handleShowCategoryContextMenu}
                    title={category.description}>
                    <div className="category-title">{category.title}</div>
                    <i className={`fal fa-angle-${showCategoryContent ? `left` : `right`}`} />
                </div>
                {showCategoryContent && (
                    <Fragment>
                        {category.items && (
                            <ul className="items-list">
                                {category.items.map(item => (
                                    <Item item={item} key={item._id} onContextMenu={this.handleShowItemContextMenu} />
                                ))}
                            </ul>
                        )}
                        {category.subcategories &&
                            category.subcategories.map(subCategory => (
                                <div className="sub-category" key={subCategory._id}>
                                    <Category category={subCategory} deleteCategory={deleteCategory} deleteItem={deleteItem} />
                                </div>
                            ))}
                    </Fragment>
                )}
                {showCategoryModal && (
                    <CategoryModal
                        categoryId={category._id}
                        environment={this}
                        fetchCategories={fetchCategories}
                        fetchLibrary={fetchLibrary}
                        isUpdate
                        name={category.title}
                        order={category.order}
                    />
                )}
            </div>
        );
    }
}

Category.propTypes = {
    category: objectOf(any).isRequired,
    deleteCategory: func.isRequired,
    deleteItem: func.isRequired,
    fetchCategories: func.isRequired,
    fetchLibrary: func.isRequired
};

export default Category;
