import React, {Component} from 'react';
import {arrayOf, any, objectOf, func, bool, string, number} from 'prop-types';
import './catgoryModal.css';
import {handleHideModalAnywhere, handleShowModal} from '../../../lib/helpers';
import {create_category, update_category} from '../../../lib/joossens_client';
import ModalTitle from '../../../components/ModalTitle';
import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Select from '../../../components/Select';
import Switch from '../../../components/Switch';
import Button from '../../../components/Button';

class CategoryModal extends Component {
    state = {
        isSubCategory: false,
        parentCategoryId: null
    };

    onChange = ({target: {value, name}}) => this.setState({[name]: value});

    onSwitch = (name, checked) =>
        this.setState({[name]: checked, parentCategoryId: checked ? this.props.categories[0]._id : null});

    createCategory = () => {
        const {categoryName, categoryDescription, parentCategoryId, categoryNameOrder} = this.state;
        const {fetchLibrary, environment, fetchCategories, isUpdate, categoryId} = this.props;

        if (isUpdate)
            update_category(categoryId, categoryName, categoryDescription, categoryNameOrder, ({message}) => {
                if (message === `Success`) {
                    fetchLibrary();
                    fetchCategories();
                    handleShowModal(`showCategoryModal`, environment);
                }
            });
        else
            create_category(categoryName, categoryDescription, parentCategoryId, ({message}) => {
                if (message === `Success`) {
                    fetchLibrary();
                    fetchCategories();
                    handleShowModal(`showCategoryModal`, environment);
                }
            });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.createCategory();
    };

    render() {
        const {categories, environment, isUpdate, order, name} = this.props;
        const {isSubCategory} = this.state;

        return (
            <div
                className="modal-wrapper"
                onClick={e => handleHideModalAnywhere(e, `showCategoryModal`, this.modalContainer, environment)}
                ref={node => (this.modalContainer = node)}>
                <form className="modal-content item-modal" onSubmit={this.handleSubmitForm}>
                    <ModalTitle title="Categorie" />
                    <div className="flex space-between">
                        <Input
                            name="categoryName"
                            onChange={this.onChange}
                            rowSpan="double"
                            title="Categorie naam"
                            value={name}
                        />
                        <Textarea
                            name="categoryDescription"
                            onChange={this.onChange}
                            rowSpan="double"
                            title="Categorie beschrijving"
                        />
                        <br />
                        {isUpdate && (
                            <Input
                                name="categoryOrder"
                                onChange={this.onChange}
                                rowSpan="double"
                                title="Categorie rangschikking"
                                value={order}
                            />
                        )}
                    </div>
                    <div className="divide-line" />
                    <Switch checked={isSubCategory} name="isSubCategory" onSwitch={this.onSwitch} title="Sub categorie" />
                    {!isUpdate &&
                        isSubCategory && (
                            <Select name="parentCategoryId" onChange={this.onChange} title="Hoofd categorie">
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>
                                        {category.title}
                                    </option>
                                ))}
                            </Select>
                        )}
                    {!isUpdate ? <Button title="Maak aan" /> : <Button title="Pas aan" />}
                </form>
            </div>
        );
    }
}

CategoryModal.propTypes = {
    categories: arrayOf(any),
    environment: objectOf(any).isRequired,
    fetchLibrary: func.isRequired,
    fetchCategories: func.isRequired,
    isUpdate: bool,
    categoryId: string,
    order: number,
    name: string
};

CategoryModal.defaultProps = {
    categories: [],
    isUpdate: false,
    categoryId: null,
    order: 0,
    name: ``
};

export default CategoryModal;
