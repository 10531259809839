import React, {Component} from 'react';
import './index.css';
import {objectOf, any, func} from 'prop-types';
import Input from '../../../../components/Input';
import ActionButton from '../../../../components/ActionButton';

import {create_folder} from '../../../../lib/joossens_client';
import {handleHideModalAnywhere, handleShowModal} from '../../../../lib/helpers';

class NewFolder extends Component {
    state = {};

    createFolder = () => {
        const {parent, environment, updateFolders} = this.props;
        const {name} = this.state;
        if (name)
            create_folder(name, parent._id, ({message}) => {
                if (message === `Success`) {
                    updateFolders();
                    handleShowModal(`showNewFolderModal`, environment);
                }
            });
    };

    onChange = ({target: {value, name}}) => this.setState({[name]: value});

    render() {
        const {environment} = this.props;

        return (
            <div
                className="new-folder-container"
                onClick={e => handleHideModalAnywhere(e, `showNewFolderModal`, this.modalContainer, environment)}
                ref={node => (this.modalContainer = node)}>
                <div className="new-folder">
                    <div className="create-folder">
                        <div className="configurator-titles">
                            <h1 className="upper">joossens</h1>
                        </div>
                        <Input name="name" onChange={this.onChange} title="Naam van de map" />
                        <div className="templates-button pointer">
                            <ActionButton onClick={this.createFolder} text="Aanmaken" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NewFolder.propTypes = {
    environment: objectOf(any).isRequired,
    parent: objectOf(any),
    updateFolders: func.isRequired
};

NewFolder.defaultProps = {
    parent: null
};

export default NewFolder;
