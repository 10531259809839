import React from 'react';
import {} from 'prop-types';
import './index.css';

const ErrorPage = () => {
    return <div>Hello, ErrorPage</div>;
};

ErrorPage.propTypes = {};

export default ErrorPage;
