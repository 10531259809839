// const API_URL = `http://api.joossens.tntap.be`;
const API_URL = `http://draw.joossens.be:81`;
// const API_URL = `http://localhost:3006`;

/**
 * USER
 *
 */

export const validate = cb => {
    let account = fetch_account();
    if (account) {
        fetch(`${API_URL}/validate`, {
            method: `GET`,
            headers: {
                authorization: `Bearer ${account.access_token} `,
                'User-Agent': `TapAuth Client/1.0`
            }
        })
            .then(r => r.json())
            .then(data => {
                if (data.message === `Failed`) removeAccount();
                cb(data);
            });
    } else {
        console.log(`Authorization error`);
    }
};

const authenticate = (client_id, client_secret, device_name, device_os, cb) => {
    fetch(`${API_URL}/token`, {
        method: `POST`,
        headers: {
            'User-Agent': `TapAuth Client/1.0`,
            'Content-Type': `application/json; charset=utf-8`
        },
        body: JSON.stringify({
            client_id: client_id,
            client_secret: client_secret
        })
    })
        .then(r => r.json())
        .then(data => {
            fetch(`${API_URL}/authenticate`, {
                method: `POST`,
                headers: {
                    'User-Agent': `TapAuth Client/1.0`,
                    'Content-Type': `application/json; charset=utf-8`
                },
                body: JSON.stringify({
                    client_id: client_id,
                    client_secret: client_secret,
                    code: data.code,
                    device_name: device_name,
                    device_os: device_os
                })
            })
                .then(r => r.json())
                .then(data => {
                    cb(data);
                })
                .catch(err => {
                    console.log(err);
                });
        })
        .catch(err => {
            console.log(err);
        });
};

export const logout = () => {
    removeAccount();
};

export const login = (username, password, cb) => {
    fetch(`${API_URL}/users/login`, {
        method: `POST`,
        headers: {
            'User-Agent': `TapAuth Client/1.0`,
            'Content-Type': `application/json; charset=utf-8`
        },
        body: JSON.stringify({
            username: username,
            password: password
        })
    })
        .then(r => r.json())
        .then(data => {
            if (data.message === `Success`) {
                console.log(data.token.client_id);
                localStorage.setItem(`user_id`, data.token.user);
                console.log(data.token);
                authenticate(
                    data.token.client_id,
                    data.token.client_secret,
                    window.navigator.userAgent,
                    window.navigator.userAgent,
                    tokens => {
                        cb(tokens);
                    }
                );
            } else {
                cb(false);
            }
        })
        .catch(err => console.log(err));
};

const fetch_account = () => JSON.parse(localStorage.getItem(`joossens_account`));
const removeAccount = () => {
    localStorage.removeItem(`joossens_account`);
    localStorage.removeItem(`user_id`);
};

export const fetch_users = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authentication error`);
    }
};

export const fetch_user = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};
export const create_user = (firstname, lastname, email, password, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users/`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email,
                password
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_user = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const update_user = (id, firstname, lastname, email, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const update_user_password = (id, firstname, lastname, email, oldPassword, password, confirmPassword, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/users/${id}/password`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email,
                password,
                oldPassword,
                confirmPassword
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * CATEGORY
 */

export const fetch_library = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/library`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_categories = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_category = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_category = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};
/**
 *
 * @param {String} title - titel van de categorie, MOET ingevuld zijn
 * @param {String} description - description van de category, MOET ingevuld zijn
 * @param {String} parent - id van de parentcategorie van deze nieuwe categorie, MAG null zijn
 * @param {Function} cb - callback function
 */
export const create_category = (title, description, parent, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                parent
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 *
 * @param {String} id - id van de category, MOET ingevuld zijn
 * @param {String} title - nieuwe title van de category, MAG null zijn
 * @param {String} description  - nieuwe description van de category, MAG null zijn
 * @param {Function} cb
 */
export const update_category = (id, title, description, order, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                order
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * CUSTOMER
 */

export const fetch_customers = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/customers`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const create_customer = (firstname, lastname, email, phone, btw, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/customers`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email,
                phone,
                btw
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_customer = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/customers/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const update_customer = (id, firstname, lastname, email, phone, btw, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/customers/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                firstname,
                lastname,
                email,
                phone,
                btw
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Auhtorization error`);
    }
};

export const delete_customer = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/customers/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * ITEMS
 */

export const fetch_items = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/items`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_item = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/items/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_item = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/items/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 *
 * @param {String} title - titel van het item, MOET ingevuld zijn
 * @param {String} description - description van het item, MOET ingevuld zijn
 * @param {Number} lenght - lengte van het item, MOET ingevuld zijn
 * @param {Number} width - breedte van het item, MOET ingevuld zijn
 * @param {String} file  - image van het item, MAG null zijn
 * @param {String} category_id - id van de category waartoe dit item zal behoren, MOET ingevuld zijn
 * @param {String} referentie - referentie naar het item, MAG null zijn
 * @param {Function} cb
 */

export const create_item = (title, description, length, width, category_id, referentie, file, cb) => {
    const formData = new FormData();
    formData.append(`file`, file);
    formData.append(`title`, title);
    formData.append(`description`, description);
    formData.append(`length`, length);
    formData.append(`width`, width);
    formData.append(`category_id`, category_id);
    formData.append(`referentie`, referentie);

    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/items`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: formData
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * @param {String} id - id van het item
 * @param {String} title - titel van het item, MAG null zijn
 * @param {String} description - description van het item, MAG null zijn
 * @param {Number} lenght - lengte van het item, MAG null zijn
 * @param {Number} width - breedte van het item, MAG null zijn
 * @param {String} image  - image van het item, MAG null zijn
 * @param {String} category_id - id van de category waartoe dit item zal behoren, MAG null zijn
 * @param {String} referentie - referentie naar het item, MAG null zijn
 * @param {Function} cb
 */
export const update_item = (id, title, description, lenght, image, width, category_id, referentie, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/items/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                length,
                width,
                image,
                category_id,
                referentie
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_orders = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/orders`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_order = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/orders/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const create_order = (title, description, project_id, price, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/orders`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                project_id,
                price
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const update_order = (id, title, description, price, project_id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/orders/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                price,
                project_id
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_order = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/orders/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * PROJECTS
 */

export const fetch_projects = cb => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/projects`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_project = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/projects/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const create_project = (title, description, customer_id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/projects`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                customer_id
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_project = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/projects/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const update_project = (id, title, description, customer_id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/projects/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                title,
                description,
                customer_id
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * FOLDERS
 */

export const fetch_folders = cb => {
    const url = `${API_URL}/folders`;
    const account = fetch_account();
    if (account) {
        fetch(url, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_folder = (id, cb) => {
    const url = `${API_URL}/folders/${id}`;
    const account = fetch_account();
    if (account) {
        fetch(url, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_folder = (id, cb) => {
    const url = `${API_URL}/folders/${id}`;
    const account = fetch_account();
    if (account) {
        fetch(url, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const create_folder = (name, parent, cb) => {
    const url = `${API_URL}/folders`;
    const account = fetch_account();
    if (account) {
        fetch(url, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({name, parent})
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * SKETCHES
 */

export const fetch_sketches = (offset, limit, cb) => {
    let url = `${API_URL}/sketches?`;
    if (offset) url = url + `offset=${offset}&`;
    if (limit) url = url + `limit=${limit}`;
    const account = fetch_account();
    if (account) {
        fetch(url, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const fetch_sketch = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/sketches/${id}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};
/**
 *
 * @param {String} title
 * @param {Number} scale
 * @param {Number} width
 * @param {Number} height
 * @param {Function} cb
 */
export const create_sketch = (paper, title, project_name, installer, address, ordernr, customer, scale, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/sketches`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                paper,
                title,
                project_name,
                installer,
                address,
                ordernr,
                customer,
                scale
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 *
 * @param {String} id - id van de sketch
 * @param {Array} createArray - dit is een array van objecten die moeten gewijzigd worden voorbeelden: createArray = [ {type: 'lijn', stroke_width: 5, x: 1, y: 2, x2: 3, y2: 4 }, { type: 'rechthoek',stroke_width: 2, x: 6,y: 7, x2: 8,y2: 9}, {type: 'cirkel',stroke_width: 3,x: 5,y: 5,radius: 10}, {type: 'item',item: 'DFGHJK456FGHJK45654565',x: 14,y: 11,stroke_width: 6}]
 * @param {Array} updateArray - dit is een array van de VOLLEDIGE objecten die geupdate moet worden voorbeeld: "updateArray" : [{"_id": "5b2b6f4b0b3bbbec58b9f570","type": "line","item": null,"stroke_width": 5,"x": 33,"y": 33,"x2": 34,"y2": 34,"sketch": "5b2b6ae2f4cc11e99a1897d6"}]
 * @param {Array} deleteArray - dit is een array van enkel de ids van de objecten die verwijderd moeten worden voorbeeld: "deleteArray": ["rdtysdufisud85138", "sdqf45654564sdfdsf", "sqdfqsdfqsd456464564"]
 * @param {Function} cb
 */
export const update_sketch = (id, createArray, updateArray, deleteArray, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/sketches/${id}`, {
            method: `PUT`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: JSON.stringify({
                createArray,
                updateArray,
                deleteArray
            })
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const delete_sketch = (id, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/sketches/${id}`, {
            method: `DELETE`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};

export const upload_image = (item_id, file, cb) => {
    const formData = new FormData();
    formData.append(`file`, file);
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/${item_id}/image`, {
            method: `POST`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                Authorization: `Bearer ${account.access_token}`
            },
            body: formData
        })
            .then(r => r.json())
            .then(answer => cb(answer))
            .catch(err => console.log(err));
    } else {
        console.log(`Authorization error`);
    }
};

export const download_image = (image_id, name) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/categories/${image_id}/image`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,

                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.blob())
            .then(blob_data => {
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob_data, name);
                } else {
                    const windowUrl = window.URL || window.webkitURL;
                    if (typeof windowUrl.createObjectURL === `function`) {
                        const blob = new Blob([blob_data], {
                            type: blob_data.type
                        });
                        const url = windowUrl.createObjectURL(blob);
                        const anchor = document.createElement(`a`);
                        anchor.setAttribute(`href`, url);
                        anchor.setAttribute(`download`, name);
                        document.body.appendChild(anchor);
                        anchor.click();
                        windowUrl.revokeObjectURL(url);
                        document.body.removeChild(anchor);
                    }
                }
            });
    } else {
        console.log(`Authorization error`);
    }
};

export const search_sketches = (query, cb) => {
    const account = fetch_account();
    if (account) {
        fetch(`${API_URL}/sketches/search?q=${query}`, {
            method: `GET`,
            headers: {
                'User-Agent': `TapAuth Client/1.0`,
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${account.access_token}`
            }
        })
            .then(r => r.json())
            .then(answer => cb(answer));
    } else {
        console.log(`Authorization error`);
    }
};
