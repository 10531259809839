import React, {Component} from 'react';
import {func, objectOf, any} from 'prop-types';
import './index.css';

import ContextMenu from '../../../components/ContextMenu';
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';

class Folder extends Component {
    state = {
        showContextMenu: false
    };

    handleShowContextMenu = e => {
        e.preventDefault();
        const {showContextMenu} = this.state;
        this.setState({showContextMenu: !showContextMenu, position: {x: e.pageX, y: e.pageY}});
    };

    deleteFolder = e => {
        const {deleteFolder, folder} = this.props;
        e.preventDefault();
        deleteFolder(folder);
    };

    openFolder = e => {
        const {folder, openFolder} = this.props;
        e.preventDefault();
        if (this.listObj === e.target || this.icon === e.target || this.name === e.target) openFolder(folder);
    };

    render() {
        const {showContextMenu, position} = this.state;
        const {folder} = this.props;

        return (
            <li
                className="template pointer folder"
                onClick={this.openFolder}
                onContextMenu={this.handleShowContextMenu}
                ref={node => (this.listObj = node)}>
                <ContextMenu position={position} show={showContextMenu} showContextMenu={this.handleShowContextMenu}>
                    <ContextMenuItem icon={<i className="fal fa-trash-alt" />} onClick={this.deleteFolder} title="Verwijder" />
                </ContextMenu>
                <i className="fal fa-folder" ref={node => (this.icon = node)} />
                <p ref={node => (this.name = node)}>{folder.name}</p>
            </li>
        );
    }
}

Folder.propTypes = {
    openFolder: func.isRequired,
    folder: objectOf(any).isRequired,
    deleteFolder: func.isRequired
};

export default Folder;
