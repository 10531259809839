import React, {Component, Fragment} from 'react';
import './sketch.css';
import {Link} from 'react-router-dom';
import html2canvas from 'html2canvas';
import async from 'async';

import {update_sketch, fetch_sketch, fetch_library, fetch_item} from '../../lib/joossens_client';
import {sketchStyle} from '../../lib/printing';
import {handleHideModalAnywhere} from '../../lib/helpers';

import {Stage, Layer, Line, Rect, Ellipse, Image, Group, Text, Arrow} from 'react-konva';

import Tool from './Tool/tool';
import Category from './Category/category';
import TransformerComponent from './TransformerComponent/transformerComponent';

import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import ModalTitle from '../../components/ModalTitle';
import Notification from '../../components/Notification';

import logo from '../../../assets/svg/logo_zwart.svg';

class Sketch extends Component {
    state = {
        sketch: JSON.parse(localStorage.getItem(`sketch`)),
        selectedSketch: JSON.parse(localStorage.getItem(`sketch`)),
        rendered: false,
        isDrawing: false,
        isErasing: false,
        isDraggable: false,
        isDragging: false,
        showClientModal: false,
        showNotification: false,
        showItems: true,
        showWallToolInfo: false,
        dashEnabled: false,
        showTextModal: false,
        clickedItem: null,
        gridSize: 10,
        strokeWidth: 10,
        selectedTool: `move`,
        selectedShapeName: ``,
        lineType: `9`,
        selectedLineColor: `grey`,
        walls: [],
        rectangles: [],
        circles: [],
        categories: [],
        items: [],
        texts: [],
        stageMouseDown: () => false,
        stageMouseUp: () => false,
        stageMouseMove: () => false,
        handleDragEnd: () => false,
        handleDragStart: () => false,
        handleDragMove: () => false,
        legend: [],
        stageScale: 1,
        stageX: 0,
        stageY: 0
    };

    componentDidMount() {
        this.setState({rendered: true, gridSize: Math.round(this.mmToPx(5))});
        this.setMovingFunctions();

        // window.addEventListener(`resize`, this.optimizedResize);
        window.addEventListener(`keypress`, this.handleKeypress);
        // window.addEventListener(`wheel`, this.handleZoom);

        this.fetchSketch();
        this.fetchLibrary();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.optimizedResize);
        window.removeEventListener(`keypress`, this.handleKeypress);
    }

    /* Scaling */
    px = 37.79;
    dpiPaper = 100; //130.517;
    devicePixelRatio = window.devicePixelRatio || 1;
    dpi = {
        x: this.devicePixelRatio * window.offsetWidth,
        y: this.devicePixelRatio * window.offsetHeight
    };

    dXY = (p1, p2) => {
        const {sqrt, pow} = Math;
        return sqrt(pow(p2.x - p1.x, 2) + pow(p2.y - p1.y, 2));
    };

    handleKeypress = ({key}) => {
        switch (key) {
            case `l`:
                this.setWallFunctions();
                break;
            case `v`:
                this.setMovingFunctions();
                break;
            case `r`:
                this.setRectFunctions();
                break;
            case `s`:
                this.setTransformFunctions();
                break;
            case `e`:
                this.setEraseFunctions();
                break;
            case `t`:
                this.setTextFunctions();
                break;
            default:
                break;
        }
    };

    toggleSidebar = () => {
        const {showItems} = this.state;
        this.setState({showItems: !showItems});
    };

    // set zoom event

    /* SKETCH */

    setInitialFunctions = () => {
        this.setState({
            stageMouseDown: this.handleSketchClick,
            stageMouseUp: () => false,
            stageMouseMove: () => false,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `mouse`
        });
    };

    handleSketchClick = ({target}) => {
        if (target === this.stage._stage) {
            return;
        }
    };

    mmToPx = mms => {
        const dpiPx = this.dpiPaper / 25.4;
        const pixels = mms * dpiPx;
        return Math.round(pixels);
    };

    pxToMm = pxs => {
        const dpiPx = this.dpiPaper / 25.4;
        const mms = pxs / dpiPx;
        return Math.round(mms);
    };

    calculateSlopeAndYIntersect = (x1, x2, y1, y2) => {
        const slope = (y2 - y1) / (x2 - x1);
        const yIntersect = y2 - x2 * slope;
        return {slope, yIntersect};
    };

    findCircleLineIntersections = (r, h, k, m, n) => {
        const a = 1 + Math.pow(m, 2);
        const b = -h * 2 + m * (n - k) * 2;
        const c = Math.pow(h, 2) + Math.pow(n - k, 2) - Math.pow(r, 2);

        var d = Math.pow(b, 2) - 4 * a * c;
        if (d >= 0) {
            // insert into quadratic formula
            var intersections = [
                (-b + Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a),
                (-b - Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a)
            ];
            if (d == 0) {
                // only 1 intersection
                return [intersections[0]];
            }
            return intersections;
        }
        // no intersection
        return [];
    };

    fetchSketch = () => {
        const {
            selectedSketch: {_id}
        } = this.state;

        fetch_sketch(_id, ({message, sketch}) => {
            if (message === `Success`) {
                const canvasWidth = this.mmToPx(sketch.paper === `A4` ? 297 : 420);
                const canvasHeight = this.mmToPx(sketch.paper === `A4` ? 210 : 297);
                console.log(`canvasWidth`, canvasWidth);
                console.log(`canvasHeight`, canvasHeight);
                let sketchWidth = this.mmToPx(420);
                let sketchHeight = this.mmToPx(297);

                if (sketch.paper === `A4`) {
                    sketchWidth = this.mmToPx(297);
                    sketchHeight = this.mmToPx(210);
                }

                sketch.canvasWidth = canvasWidth - 30;
                sketch.canvasHeight = canvasHeight - 30;
                sketch.sketchWidth = sketchWidth;
                sketch.sketchHeight = sketchHeight;

                console.log(`sketchWidth`, sketchWidth);
                console.log(`sketchHeight`, sketchHeight);

                this.setState({sketch}, () => {
                    this.stage._stage.batchDraw();
                    this.filterSketch();
                });
            }
        });
    };

    filterSketch = () => {
        const {sketch} = this.state;
        const walls = [];
        const rectangles = [];
        const circles = [];
        const items = [];
        const texts = [];
        sketch.lines.map((line, index) => {
            const object = {
                _id: line._id,
                beginX: line.x,
                beginY: line.y,
                endX: line.x2,
                endY: line.y2,
                stroke_width: line.stroke_width,
                rotation: line.rotation,
                dashEnabled: line.dashEnabled,
                color: line.color,
                index,
                opacity: 1,
                stroke: `grey`,
                type: `line`
            };

            walls.push(object);
        });

        sketch.rectangles.map((rectangle, index) => {
            const object = {
                _id: rectangle._id,
                x: rectangle.x,
                y: rectangle.y,
                height: rectangle.y2,
                width: rectangle.x2,
                stroke_width: rectangle.stroke_width,
                rotation: rectangle.rotation,
                index,
                stroke: `grey`,
                opacity: 1,
                type: `rectangle`
            };
            rectangles.push(object);
        });

        sketch.circles.map((circle, index) => {
            const object = {
                _id: circle._id,
                x: circle.x,
                y: circle.y,
                radius: circle.radius,
                stroke_width: circle.stroke_width,
                rotation: circle.rotation,
                index,
                stroke: `grey`,
                opacity: 1,
                type: `circle`
            };
            circles.push(object);
        });
        sketch.texts.map((text, index) => {
            const object = {
                _id: text._id,
                x: text.x,
                y: text.y,
                index,
                type: `text`,
                text: text.text
            };
            texts.push(object);
        });

        sketch.items.map((item, index) => {
            const image = new window.Image();
            image.src = item.item.image;
            image.crossOrigin = `Anonymous`;
            image.onload = () => {
                const object = {
                    _id: item._id,
                    item_id: item.item._id,
                    x: item.x,
                    y: item.y,
                    rotation: item.rotation,
                    width: item.item.width,
                    origWidth: item.item.width,
                    height: item.item.length,
                    origHeight: item.item.height,
                    image,
                    b64: item.item.imageB64,
                    index,
                    type: `image`
                };
                items.push(object);
                this.setState({items}, this.generateLegend);
            };
        });

        this.setState({items, circles, rectangles, walls, texts});
    };

    makeDeleteArray = () => {
        const {walls, rectangles, circles, sketch, items, texts} = this.state;
        const deleteArray = [];
        if (sketch) {
            sketch.lines.map(line => {
                if (walls.length === 0) {
                    deleteArray.push(line._id);
                } else {
                    let toDelete = true;
                    walls.map(wall => {
                        if (wall._id === line._id) {
                            toDelete = false;
                        }
                    });
                    if (toDelete) {
                        deleteArray.push(line._id);
                    }
                }
            });
            sketch.items.map(originalItem => {
                if (items.length === 0) {
                    deleteArray.push(originalItem._id);
                } else {
                    let toDelete = true;
                    items.map(item => {
                        if (item._id === originalItem._id) {
                            toDelete = false;
                        }
                    });
                    if (toDelete) {
                        deleteArray.push(originalItem._id);
                    }
                }
            });

            sketch.rectangles.map(rectangle => {
                if (rectangles.length === 0) {
                    deleteArray.push(rectangle._id);
                } else {
                    let toDelete = true;
                    rectangles.map(rectanglesCurrent => {
                        if (rectanglesCurrent._id === rectangle._id) {
                            toDelete = false;
                        }
                    });

                    if (toDelete) {
                        deleteArray.push(rectangle._id);
                    }
                }
            });

            sketch.circles.map(circle => {
                if (circles.length === 0) {
                    deleteArray.push(circle._id);
                } else {
                    let toDelete = true;
                    circles.map(circleCurrent => {
                        if (circleCurrent._id === circle._id) {
                            toDelete = false;
                        }
                    });
                    if (toDelete) {
                        deleteArray.push(circle._id);
                    }
                }
            });
            sketch.texts.map(text => {
                if (texts.length === 0) {
                    deleteArray.push(text._id);
                } else {
                    let toDelete = true;
                    texts.map(textCurrent => {
                        if (textCurrent._id === text._id) {
                            toDelete = false;
                        }
                    });
                    if (toDelete) {
                        deleteArray.push(text._id);
                    }
                }
            });

            return deleteArray;
        } else return null;
    };

    makeUpdateArray = () => {
        const {walls, rectangles, circles, sketch, strokeWidth, items, texts} = this.state;

        const updateArray = [];
        if (sketch) {
            sketch.lines.map(line => {
                if (walls.length > 0) {
                    walls.map(wall => {
                        let toUpdate = false;
                        if (wall._id === line._id) {
                            if (line.x !== wall.beginX) toUpdate = true;
                            if (line.y !== wall.beginY) toUpdate = true;
                            if (line.x2 !== wall.endX) toUpdate = true;
                            if (line.y2 !== wall.endY) toUpdate = true;
                            if (line.rotation !== wall.rotation) toUpdate = true;
                            if (line.stroke_width !== wall.stroke_width) toUpdate = true;
                            if (line.color !== wall.color) toUpdate = true;
                            if (line.dashEnabled !== wall.dashEnabled) toUpdate = true;
                        }
                        if (toUpdate) {
                            const object = {
                                _id: wall._id,
                                type: `line`,
                                stroke_width: wall.stroke_width,
                                x: wall.beginX,
                                y: wall.beginY,
                                x2: wall.endX,
                                y2: wall.endY,
                                dashEnabled: wall.dashEnabled,
                                color: wall.color,
                                rotation: wall.rotation,
                                sketch: sketch._id
                            };
                            updateArray.push(object);
                        }
                    });
                }
            });
            sketch.items.map(originalItem => {
                if (items.length > 0) {
                    items.map(item => {
                        let toUpdate = false;
                        if (item._id === originalItem._id) {
                            if (originalItem.x !== item.x) toUpdate = true;
                            if (originalItem.y !== item.y) toUpdate = true;
                            if (originalItem.rotation !== item.rotation) toUpdate = true;
                        }
                        if (toUpdate) {
                            const object = {
                                _id: item._id,
                                type: `item`,
                                stroke_width: strokeWidth,
                                x: item.x,
                                y: item.y,
                                rotation: item.rotation,
                                item: item.item_id,
                                sketch: sketch._id
                            };
                            updateArray.push(object);
                        }
                    });
                }
            });
            sketch.texts.map(originalText => {
                if (texts.length > 0) {
                    texts.map(text => {
                        let toUpdate = false;
                        if (text._id === originalText._id) {
                            if (originalText.x !== text.x) toUpdate = true;
                            if (originalText.y !== text.y) toUpdate = true;
                            if (originalText.text !== text.text) toUpdate = true;
                        }
                        if (toUpdate) {
                            const object = {
                                _id: text._id,
                                type: `text`,
                                x: text.x,
                                y: text.y,
                                text: text.text,
                                sketch: sketch._id
                            };
                            updateArray.push(object);
                        }
                    });
                }
            });

            sketch.rectangles.map(rectangle => {
                if (rectangles.length > 0) {
                    rectangles.map(rectangleCurrent => {
                        let toUpdate = false;
                        if (rectangle._id === rectangleCurrent._id) {
                            if (rectangle.x !== rectangleCurrent.x) toUpdate = true;
                            if (rectangle.y !== rectangleCurrent.y) toUpdate = true;
                            if (rectangle.rotation !== rectangleCurrent.rotation) toUpdate = true;
                            if (rectangle.x2 !== rectangleCurrent.width) toUpdate = true;
                            if (rectangle.y2 !== rectangleCurrent.height) toUpdate = true;
                            if (rectangle.stroke_width !== rectangleCurrent.stroke_width) toUpdate = true;
                        }
                        if (toUpdate) {
                            const object = {
                                _id: rectangleCurrent._id,
                                type: `rectangle`,
                                stroke_width: rectangleCurrent.stroke_width,
                                x: rectangleCurrent.x,
                                y: rectangleCurrent.y,
                                x2: rectangleCurrent.width,
                                y2: rectangleCurrent.height,
                                rotation: rectangleCurrent.rotation,
                                sketch: sketch._id
                            };
                            updateArray.push(object);
                        }
                    });
                }
            });

            sketch.circles.map(circle => {
                if (circles.length > 0) {
                    circles.map(circleCurrent => {
                        let toUpdate = false;
                        if (circle._id === circleCurrent._id) {
                            if (circle.x !== circleCurrent.x) toUpdate = true;
                            if (circle.y !== circleCurrent.y) toUpdate = true;
                            if (circle.rotation !== circleCurrent.rotation) toUpdate = true;
                            if (circle.radius.x !== circleCurrent.radius.x) toUpdate = true;
                            if (circle.radius.y !== circleCurrent.radius.y) toUpdate = true;
                            if (circle.stroke_width !== circleCurrent.stroke_width) toUpdate = true;
                        }
                        if (toUpdate) {
                            const object = {
                                _id: circleCurrent._id,
                                type: `circle`,
                                x: circleCurrent.x,
                                y: circleCurrent.y,
                                rotation: circleCurrent.rotation,
                                stroke_width: circleCurrent.stroke_width,
                                radius: circleCurrent.radius,
                                sketch: sketch._id
                            };
                            updateArray.push(object);
                        }
                    });
                }
            });
            return updateArray;
        } else return null;
    };

    makeCreateArray = () => {
        const {walls, rectangles, circles, items, sketch, strokeWidth, texts} = this.state;
        const createArray = [];

        if (sketch) {
            if (walls.length > 0) {
                walls.map(wall => {
                    let toCreate = true;
                    sketch.lines.map(line => {
                        if (line._id === wall._id) {
                            toCreate = false;
                        }
                    });
                    if (toCreate) {
                        const object = {
                            type: `line`,
                            stroke_width: wall.stroke_width,
                            x: wall.beginX,
                            y: wall.beginY,
                            x2: wall.endX,
                            y2: wall.endY,
                            color: wall.color,
                            dashEnabled: wall.dashEnabled,
                            rotation: wall.rotation
                        };
                        createArray.push(object);
                    }
                });
            }
            if (items.length > 0) {
                items.map(item => {
                    let toCreate = true;
                    sketch.items.map(originalItem => {
                        if (item._id === originalItem._id) {
                            toCreate = false;
                        }
                    });
                    if (toCreate) {
                        const object = {
                            type: `item`,
                            stroke_width: strokeWidth,
                            x: item.x,
                            y: item.y,
                            rotation: item.rotation,
                            item_id: item.item_id
                        };
                        createArray.push(object);
                    }
                });
            }

            if (rectangles.length > 0) {
                rectangles.map(rectangle => {
                    let toCreate = true;
                    sketch.rectangles.map(originalRectangle => {
                        if (originalRectangle._id === rectangle._id) {
                            toCreate = false;
                        }
                    });
                    if (toCreate) {
                        const object = {
                            type: `rectangle`,
                            stroke_width: rectangle.stroke_width,
                            x: rectangle.x,
                            y: rectangle.y,
                            rotation: rectangle.rotation,
                            x2: rectangle.width,
                            y2: rectangle.height
                        };
                        createArray.push(object);
                    }
                });
            }

            if (circles.length > 0) {
                circles.map(circle => {
                    let toCreate = true;
                    sketch.circles.map(originalCircle => {
                        if (originalCircle._id === circle._id) {
                            toCreate = false;
                        }
                    });

                    if (toCreate) {
                        const object = {
                            type: `circle`,
                            stroke_width: circle.stroke_width,
                            x: circle.x,
                            y: circle.y,
                            rotation: circle.rotation,
                            radius: circle.radius
                        };
                        createArray.push(object);
                    }
                });
            }
            if (texts.length > 0) {
                texts.map(t => {
                    let toCreate = true;
                    sketch.texts.map(originalT => {
                        if (originalT._id === t._id) {
                            toCreate = false;
                        }
                    });

                    if (toCreate) {
                        const object = {
                            type: `text`,
                            x: t.x,
                            y: t.y,
                            text: t.text,
                            color: `green`
                        };
                        createArray.push(object);
                    }
                });
            }

            return createArray;
        } else return null;
    };

    handleSaveSketch = () => {
        const {selectedSketch} = this.state;

        const deleteArray = this.makeDeleteArray();
        const updateArray = this.makeUpdateArray();
        const createArray = this.makeCreateArray();

        update_sketch(
            selectedSketch._id,
            createArray.length > 0 ? createArray : null,
            updateArray.length > 0 ? updateArray : null,
            deleteArray.length > 0 ? deleteArray : null,
            data => {
                if (data.message === `Success`) {
                    this.setState({showNotification: true});
                    setTimeout(() => {
                        this.setState({showNotification: false});
                    }, 3000);
                    this.fetchSketch();
                }
            }
        );
    };

    /* END SKETCH */

    /* LIBRARY */

    fetchLibrary = () => fetch_library(({message, categories}) => message === `Success` && this.setState({categories}));

    /* END LIBRARY */

    optimizedResize = () => {
        const resize = () => {
            this.forceUpdate();
        };
        let running = false;

        if (!running) {
            running = true;

            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(resize);
            }
        }
    };

    onChange = ({target: {value, name}}) => this.setState({[name]: value});

    onChangeRadio = ({target: {value, name}}) => {
        let strokeWidth;
        let dashEnabled;

        switch (value) {
            case `9`:
                dashEnabled = false;
                strokeWidth = 9;
                break;
            case `14`:
                dashEnabled = false;
                strokeWidth = 14;
                break;
            case `help`:
                strokeWidth = 1;
                dashEnabled = true;
                break;
            default:
                break;
        }
        this.setState({[name]: value, strokeWidth, dashEnabled});
    };

    handleClearStage = () => this.setState({walls: [], rectangles: [], circles: [], items: []});

    drawGrid = () => {
        const {gridSize} = this.state;
        const grid = [];

        for (let i = 0; i < this.canvasContainer.offsetWidth / gridSize; i++) {
            grid.push(
                <Line
                    key={i}
                    points={[
                        Math.round(i * gridSize) + 0.5,
                        0,
                        Math.round(i * gridSize) + 0.5,
                        this.canvasContainer.offsetHeight
                    ]}
                    stroke="#ddd"
                    strokeWidth={1}
                />
            );
        }

        for (let i = 0; i < this.canvasContainer.offsetHeight / gridSize; i++) {
            grid.push(
                <Line
                    key={(i + 1) * 1354}
                    points={[0, Math.round(i * gridSize), this.canvasContainer.offsetWidth, Math.round(i * gridSize)]}
                    stroke="#ddd"
                    strokeWidth={1}
                />
            );
        }

        // for (let i = 0; i < this.canvasContainer.offsetWidth / (gridSize / 10); i++) {
        //     grid.push(
        //         <Line
        //             key={i}
        //             points={[
        //                 Math.round(i * (gridSize / 10)) + 0.5,
        //                 0,
        //                 Math.round(i * (gridSize / 10)) + 0.5,
        //                 this.canvasContainer.offsetHeight
        //             ]}
        //             stroke="#ddd"
        //             strokeWidth={1}
        //         />
        //     );
        // }

        // for (let i = 0; i < this.canvasContainer.offsetHeight / (gridSize / 10); i++) {
        //     grid.push(
        //         <Line
        //             key={(i + 1) * 1354}
        //             points={[
        //                 0,
        //                 Math.round(i * (gridSize / 10)),
        //                 this.canvasContainer.offsetWidth,
        //                 Math.round(i * (gridSize / 10))
        //             ]}
        //             stroke="#ddd"
        //             strokeWidth={1}
        //         />
        //     );
        // }

        return grid.map(line => line);
    };

    /*  WALLS  */

    setWallFunctions = () => {
        this.stage._stage.container().className = ``;

        this.setState({
            stageMouseDown: this.handleStartWallDraw,
            stageMouseUp: this.handleEndWallDraw,
            stageMouseMove: this.handlePreviewWall,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `line`,
            showWallToolInfo: true
        });
    };

    p1 = null;

    handleStartWallDraw = () => {};

    handleEndWallDraw = ({target}) => {
        const {walls, strokeWidth, isDrawing, dashEnabled, selectedLineColor} = this.state;
        const {x, y} = target.getStage().getPointerPosition();

        if (!isDrawing) {
            this.p1 = {x, y};
            const wall = {
                beginX: this.p1.x,
                beginY: this.p1.y,
                endX: x,
                endY: y,
                stroke: `#bdbdbd`,
                stroke_width: strokeWidth,
                dashEnabled,
                color: selectedLineColor,
                opacity: 1,
                index: walls.length === 0 ? 0 : walls.length
            };
            window.addEventListener(`keyup`, this.cancelWallDraw);
            this.setState({walls: [...walls, wall], isDrawing: true});
        } else if (this.calculateCurrentWallLength(this.p1.x, this.p1.y, x, y) > 0) {
            this.setState({isDrawing: false});
        }
    };

    cancelWallDraw = e => {
        if (e.keyCode === 27) {
            const {walls} = this.state;
            this.p1 = null;
            const newWallsList = walls.slice();
            newWallsList.pop();
            this.setState({isDrawing: false, walls: newWallsList});
            window.removeEventListener(`keyup`, this.cancelWallDraw);
        }
    };

    handlePreviewWall = ({target}) => {
        const {isDrawing, walls} = this.state;
        if (isDrawing && this.p1) {
            const {x, y} = target.getStage().getPointerPosition();
            if (
                target === target.getStage() ||
                target.className === `Rect` ||
                target.className === `Line` ||
                target.className === `Ellipse` ||
                target.className === `Image` ||
                target.className === `Text`
            ) {
                const newWallsList = walls.slice();
                const currWall = newWallsList.pop();

                currWall.endX = x;
                currWall.endY = y;

                this.setState({walls: [...newWallsList, currWall]});
            }
        }
    };

    calculateCurrentWallLength = (x1, y1, x2, y2) => {
        const {sketch} = this.state;
        const pixels = this.dXY({x: x1, y: y1}, {x: x2, y: y2});
        const mms = this.pxToMm(pixels);
        const lineLength = mms * sketch.scale;
        return lineLength;
    };

    generateWallLength = wall => {
        const {beginX, beginY, endX, endY, stroke_width} = wall;
        const {sketch} = this.state;
        let horizontal;
        let vertical;
        let diagonal;

        if (beginY === endY) {
            horizontal = true;
            vertical = false;
            diagonal = false;
        } else horizontal = false;

        if (beginX === endX) {
            vertical = true;
            diagonal = false;
            horizontal = false;
        } else vertical = false;

        if (beginX !== endX && beginY !== endY) {
            diagonal = true;
            vertical = false;
            horizontal = false;
        } else diagonal = false;

        const {abs} = Math;
        const pixels = this.dXY({x: beginX, y: beginY}, {x: endX, y: endY});
        // const dpi = 141.217949;

        const mms = this.pxToMm(pixels);
        const lineLength = mms * sketch.scale; //scale
        // const lineLength = mms * 1; //scale

        if (horizontal) {
            let x = beginX + (endX - beginX) / 2 - 25;
            let y = beginY - (60 + stroke_width);
            if (beginY >= this.canvasContainer.clientHeight / 2) {
                y = beginY + 25;
            }
            const text = `${abs(lineLength).toFixed(0)}`;
            return {
                text: <Text fill="green" fontSize={15} text={text} x={x} y={y} />,
                length: `${abs(lineLength).toFixed(0)}mm`
            };
        } else if (vertical) {
            let x = beginX - (60 + stroke_width);
            let y = beginY + (endY - beginY) / 2 - 35;
            if (beginX >= this.canvasContainer.clientWidth / 2) {
                x = beginX + 30;
            }
            const text = `${abs(lineLength).toFixed(0)}`;
            return {
                text: <Text fill="green" fontSize={15} text={text} x={x} y={y} />,
                length: `${abs(lineLength).toFixed(0)}mm`
            };
        } else if (diagonal) {
            let x = beginX + (endX - beginX) / 2 - (60 + stroke_width);
            let y = beginY + (endY - beginY) / 2 - 55;
            if (beginX >= this.canvasContainer.clientWidth / 2) {
                x = beginX + (endX - beginX) / 2 + 30;
            }
            if (beginY >= this.canvasContainer.clientHeight / 2) {
                y = beginY + (endY - beginY) / 2 + 40;
            }
            const text = `${abs(lineLength).toFixed(0)}`;
            return {
                text: <Text fill="green" fontSize={15} text={text} x={x} y={y} />,
                length: `${abs(lineLength).toFixed(0)}mm`
            };
        }
    };

    changeWallPos = ({currentTarget: {value}}, wall) => {
        const {walls, sketch} = this.state;
        const filteredWalls = walls.filter(w => wall._id !== w._id);

        if (parseInt(value) > 0) {
            const {slope, yIntersect} = this.calculateSlopeAndYIntersect(wall.beginX, wall.endX, wall.beginY, wall.endY);

            const r = this.mmToPx(parseInt(value)) / sketch.scale;
            const intersections = this.findCircleLineIntersections(r, wall.beginX, wall.beginY, slope, yIntersect).filter(
                x => x >= 0
            );
            const newX = intersections[0];
            const newY = intersections[0] * slope + yIntersect;

            wall.endX = newX;
            wall.endY = newY;
            // if (coordinate === `length`) {
            //     if (wall.beginY === wall.endY) {
            //         let newL = Math.round(this.mmToPx(value));
            //         if (value === `0` || value === `` || value === 0) {
            //             newL === this.mmToPx(1);
            //         }
            //         wall.endX = wall.beginX + newL;
            //     } else if (wall.beginX === wall.endX) {
            //         let newL = Math.round(this.mmToPx(value));
            //         if (value === `0` || value === `` || value === 0) {
            //             newL === this.mmToPx(1);
            //         }

            //         wall.endY = wall.beginY + newL;
            //     } else if (wall.beginX !== wall.endX && wall.beginY !== wall.endY) {
            //         // NOthing
            //     }
            // }
            this.setState({walls: [...filteredWalls, wall]});
        }
    };

    /*  END WALLS  */

    /* TEXT */

    setTextFunctions = () => {
        this.stage._stage.container().className = ``;
        this.setState({
            stageMouseDown: this.handleStartTextDraw,
            stageMouseUp: () => false,
            stageMouseMove: () => false,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `text`,
            showWallToolInfo: false
        });
    };

    handleStartTextDraw = ({target}) => {
        const {texts} = this.state;
        const {x, y} = target.getStage().getPointerPosition();

        const t = {
            x,
            y,
            color: `green`,
            opacity: 1,
            text: ``,
            index: texts.length === 0 ? 0 : texts.length
        };
        this.setState({texts: [...texts, t], isDrawing: true, showTextModal: true});
    };

    onTextChange = ({target: {value}}) => {
        const {texts} = this.state;

        const currentText = texts[texts.length - 1];
        const filteredText = texts.filter(t => t !== currentText);

        currentText.text = value;

        this.setState({texts: [...filteredText, currentText]});
    };

    /* END TEXT */

    /*  RECTANGLES  */

    setRectFunctions = () => {
        this.stage._stage.container().className = ``;
        this.setState({
            stageMouseDown: this.handleStartRectDraw,
            stageMouseUp: this.handleEndRectDraw,
            stageMouseMove: this.handlePreviewRect,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `rect`,
            showWallToolInfo: false
        });
    };

    handleStartRectDraw = ({target}) => {
        const {rectangles} = this.state;
        const {x, y} = target.getStage().getPointerPosition();

        const rectangle = {
            x: x,
            y: y,
            // x: Math.round(x / gridSize) * gridSize,
            // y: Math.round(y / gridSize) * gridSize,
            width: 0,
            height: 0,
            stroke: `#bdbdbd`,
            stroke_width: `9`,
            opacity: 1,
            rotation: 0,
            index: rectangles.length === 0 ? 0 : rectangles.length
        };

        this.setState({rectangles: [...rectangles, rectangle], isDrawing: true});
    };

    handleEndRectDraw = ({target}) => {
        const {rectangles, isDrawing} = this.state;
        if (isDrawing) {
            const {x, y} = target.getStage().getPointerPosition();
            const newRectanglesList = rectangles.slice();
            const currRectangle = newRectanglesList.pop();

            // const newWidth = Math.round((x - currRectangle.x) / gridSize) * gridSize;
            // const newHeight = Math.round((y - currRectangle.y) / gridSize) * gridSize;
            const newWidth = x - currRectangle.x;
            const newHeight = y - currRectangle.y;

            // if (currRectangle.width === newWidth || currRectangle.height === newHeight) {
            //     this.setState({rectangles: newRectanglesList, isDrawing: false});
            // } else {
            currRectangle.width = newWidth;
            currRectangle.height = newHeight;
            currRectangle.stroke = `grey`;

            this.setState({rectangles: [...newRectanglesList, currRectangle], isDrawing: false});
            // }
        }
    };

    handlePreviewRect = ({target}) => {
        const {isDrawing, rectangles} = this.state;

        if (
            isDrawing &&
            rectangles.length > 0 &&
            (target === target.getStage() ||
                target.className === `Rect` ||
                target.className === `Line` ||
                target.className === `Ellipse` ||
                target.className === `Image` ||
                target.className === `Text`)
        ) {
            const {x, y} = target.getStage().getPointerPosition();
            const newRectanglesList = rectangles.slice();
            const currRectangle = newRectanglesList.pop();

            const newWidth = x - currRectangle.x;
            const newHeight = y - currRectangle.y;

            currRectangle.width = newWidth;
            currRectangle.height = newHeight;

            this.setState({rectangles: [...newRectanglesList, currRectangle]});
        }
    };

    handleRectEnter = ({target: {index}}) => {
        const {rectangles} = this.state;
        rectangles[index].fill = `#f7f7f7`;
        rectangles[index].opacity = 0.5;

        this.forceUpdate();
    };

    handleRectLeave = ({target: {index}}) => {
        const {rectangles} = this.state;
        rectangles[index].fill = ``;
        rectangles[index].opacity = 1;

        this.forceUpdate();
    };

    /*  END RECTANGLES  */

    /*  CIRCLES  */

    setCircleFunctions = () =>
        this.setState({
            stageMouseDown: this.handleStartCircleDraw,
            stageMouseUp: this.handleEndCircleDraw,
            stageMouseMove: this.handlePreviewCircle,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `circle`,
            showWallToolInfo: false
        });

    handleStartCircleDraw = ({target}) => {
        const {circles, gridSize} = this.state;
        const {x, y} = target.getStage().getPointerPosition();

        const circle = {
            x: Math.round(x / gridSize) * gridSize,
            y: Math.round(y / gridSize) * gridSize,
            originalXPos: Math.round(x / gridSize) * gridSize,
            originalYPos: Math.round(y / gridSize) * gridSize,
            radius: {
                x: 0,
                y: 0
            },
            stroke: `#bdbdbd`,
            index: circles.length === 0 ? 0 : circles.length
        };

        this.setState({circles: [...circles, circle], isDrawing: true});
    };

    handleEndCircleDraw = ({target}) => {
        const {circles, gridSize, isDrawing} = this.state;
        if (isDrawing) {
            const {x, y} = target.getStage().getPointerPosition();
            const newCirclesList = circles.slice();
            const currCircle = newCirclesList.pop();

            const newXR = Math.round((x - currCircle.originalXPos) / gridSize) * gridSize;
            const newYR = Math.round((y - currCircle.originalYPos) / gridSize) * gridSize;

            if (currCircle.radius.x === newXR || currCircle.radius.y === newYR) {
                this.setState({circles: newCirclesList, isDrawing: false});
            } else {
                currCircle.radius.x = Math.abs(newXR);
                currCircle.radius.y = Math.abs(newYR);

                currCircle.stroke = `grey`;

                this.setState({circles: [...newCirclesList, currCircle], isDrawing: false});
            }
        }
    };

    handlePreviewCircle = ({target}) => {
        const {isDrawing, circles} = this.state;

        if (
            isDrawing &&
            circles.length > 0 &&
            (target === target.getStage() ||
                target.className === `Rect` ||
                target.className === `Line` ||
                target.className === `Ellipse`)
        ) {
            const {x, y} = target.getStage().getPointerPosition();
            const newCirclesList = circles.slice();
            const currCircle = newCirclesList.pop();

            const newXR = x - currCircle.originalXPos;
            const newYR = y - currCircle.originalYPos;

            currCircle.radius.x = Math.abs(newXR);
            currCircle.radius.y = Math.abs(newYR);

            this.setState({circles: [...newCirclesList, currCircle]});
        }
    };

    /*  END CIRCLES  */

    /*  ERASER  */

    setEraseFunctions = () => {
        // this.stage._stage.container().classList.add(`erase-cursor`);

        this.setState({
            stageMouseDown: this.handleEraserDown,
            stageMouseUp: this.handleEraserUp,
            stageMouseMove: () => false,
            handleDragEnd: () => false,
            handleDragStart: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `eraser`,
            showWallToolInfo: false
        });
    };

    handleEraserDown = ({target}) => {
        this.setState({isErasing: true});

        const parent = target.parent;

        const {rectangles, walls, circles, items, texts} = this.state;

        const textIndex = parent.index;
        const rectangleIndex = parent.index - texts.length;
        const wallIndex = parent.index - rectangles.length - texts.length;
        const circleIndex = parent.index - walls.length - rectangles.length - texts.length;
        const itemIndex = parent.index - walls.length - rectangles.length - circles.length - texts.length;

        switch (target.className) {
            case `Rect`:
                this.setState({rectangles: rectangles.filter(rectangle => rectangle !== rectangles[rectangleIndex])});
                break;
            case `Line`:
                this.setState({walls: walls.filter(wall => wall !== walls[wallIndex])});
                break;
            case `Ellipse`:
                this.setState({circles: circles.filter(circle => circle !== circles[circleIndex])});
                break;
            case `Image`:
                this.setState({items: items.filter(item => item !== items[itemIndex])}, this.generateLegend);
                break;
            case `Text`:
                this.setState({texts: texts.filter(t => t !== texts[textIndex])});
                break;
            default:
                break;
        }
    };

    handleEraserUp = () => this.setState({isErasing: false});

    handleEraserMove = () => {
        const {isErasing} = this.state;

        // if (isErasing) {
        //     const parent = target.parent;

        //     const {rectangles, walls, circles, items} = this.state;

        //     const rectangleIndex = parent.index;
        //     const wallIndex = parent.index - rectangles.length;
        //     const circleIndex = parent.index - walls.length - rectangles.length;
        //     const itemIndex = parent.index - walls.length - rectangles.length - circles.length;

        //     switch (target.className) {
        //         case `Rect`:
        //             this.setState({rectangles: rectangles.filter(rectangle => rectangle !== rectangles[rectangleIndex])});
        //             break;
        //         case `Line`:
        //             this.setState({walls: walls.filter(wall => wall !== walls[wallIndex])});
        //             break;
        //         case `Ellipse`:
        //             this.setState({circles: circles.filter(circle => circle !== circles[circleIndex])});
        //             break;
        //         case `Image`:
        //             this.setState({items: items.filter(item => item !== items[itemIndex])});
        //             break;
        //         default:
        //             break;
        //     }
        // }
    };

    /*  END ERASER  */

    /*  MOVE  */

    setMovingFunctions = () => {
        this.setState({
            stageMouseDown: this.stageMouseDownMove,
            stageMouseUp: () => false,
            stageMouseMove: () => false,
            handleDragStart: this.handleDragStart,
            handleDragEnd: this.handleDragEnd,
            handleDragMove: this.handleDragMove,
            isDraggable: true,
            selectedTool: `move`,
            showWallToolInfo: false
        });
    };

    stageMouseDownMove = ({target}) => !target.attrs.name && this.setState({selectedShapeName: ``});

    handleDragStart = ({target}) => {
        const {isDragging} = this.state;

        if (!isDragging) {
            const {rectangles, walls, circles, items, texts} = this.state;
            const textIndex = target.index;
            const rectangleIndex = target.index - texts.length;
            const wallIndex = target.index - rectangles.length - texts.length;
            const circleIndex = target.index - walls.length - rectangles.length - texts.length;
            const itemIndex = target.index - walls.length - rectangles.length - circles.length - texts.length;

            if (target.nodeType === `Group`) {
                const childNames = target.children.map(({className}) => className);

                if (childNames.includes(`Line`)) {
                    const draggingItem = walls.find(wall => wall === walls[wallIndex]);
                    this.setState({draggingItem, draggingIndex: target.index, isDragging: true});
                    return;
                } else if (childNames.includes(`Rect`) && !childNames.includes(`Image`)) {
                    const draggingItem = rectangles.find(rect => rect === rectangles[rectangleIndex]);

                    this.setState({draggingItem, draggingIndex: target.index, isDragging: true});
                    return;
                } else if (childNames.includes(`Image`) && !childNames.includes(`Rect`)) {
                    const draggingItem = items.find(item => item === items[itemIndex]);
                    this.setState({draggingItem, draggingIndex: target.index, isDragging: true});
                    return;
                } else if (
                    childNames.includes(`Text`) &&
                    !childNames.includes(`Rect`) &&
                    !childNames.includes(`Line`) &&
                    !childNames.includes(`Image`)
                ) {
                    const draggingItem = texts.find(t => t === texts[textIndex]);
                    this.setState({draggingItem, draggingIndex: target.index, isDragging: true});
                    return;
                }
            } else if (target.nodeType === `Shape`) {
                switch (target.className) {
                    case `Ellipse`: {
                        const draggingItem = circles.find(circle => circle === circles[circleIndex]);
                        this.setState({draggingItem, draggingIndex: target.index, isDragging: true});
                        break;
                    }
                    default:
                        break;
                }
                target.moveToTop();
            }
        }
    };

    handleDragEnd = ({target}) => {
        const {isDragging} = this.state;

        if (isDragging) {
            const {rectangles, walls, circles, items, gridSize, draggingItem, draggingIndex, texts} = this.state;

            const targetPos = {
                x: target.x(),
                y: target.y()
            };

            if (target.nodeType === `Group`) {
                const childNames = target.children.map(({className}) => className);

                if (childNames.includes(`Line`)) {
                    const newWallList = walls.filter(wall => wall !== draggingItem);

                    const checkedItem = this.checkWallBoundaries(targetPos, draggingItem);

                    newWallList.splice(draggingIndex, 0, checkedItem);

                    this.setState({walls: newWallList, isDragging: false});
                    return;
                } else if (childNames.includes(`Rect`) && !childNames.includes(`Image`)) {
                    const newRectanglesList = rectangles.filter(rect => rect !== draggingItem);

                    const checkedItem = this.checkRectStageBoundaries(targetPos, draggingItem);

                    newRectanglesList.splice(draggingIndex, 0, checkedItem);

                    this.setState({rectangles: newRectanglesList, isDragging: false});
                    return;
                } else if (childNames.includes(`Image`) && !childNames.includes(`Rect`)) {
                    const newItemList = items.filter(item => item !== draggingItem);

                    const checkedItem = this.checkItemStageBoundaries(targetPos, draggingItem);

                    newItemList.splice(draggingIndex, 0, checkedItem);

                    this.setState({items: newItemList, isDragging: false});
                    return;
                } else if (
                    childNames.includes(`Text`) &&
                    !childNames.includes(`Rect`) &&
                    !childNames.includes(`Line`) &&
                    !childNames.includes(`Image`)
                ) {
                    const newTextList = texts.filter(t => t !== draggingItem);

                    draggingItem.y = targetPos.y;
                    draggingItem.x = targetPos.x;

                    newTextList.splice(draggingIndex, 0, draggingItem);

                    this.setState({texts: newTextList, isDragging: false});
                    return;
                }

                target.setZIndex(draggingIndex);
            } else if (target.nodeType === `Shape`) {
                switch (target.className) {
                    case `Ellipse`: {
                        const newCirclesList = circles.filter(circle => circle !== draggingItem);
                        draggingItem.x = Math.round(target.x() / gridSize) * gridSize;
                        draggingItem.y = Math.round(target.y() / gridSize) * gridSize;

                        newCirclesList.splice(draggingIndex, 0, draggingItem);

                        this.setState({circles: newCirclesList, isDragging: false});
                        break;
                    }
                    default:
                        break;
                }
                target.setZIndex(draggingIndex);
            }
        }
    };

    checkWallBoundaries = (position, draggingItem) => {
        const {x, y} = position;

        draggingItem.beginX += x;
        draggingItem.endX += x;
        draggingItem.beginY += y;
        draggingItem.endY += y;

        return draggingItem;
    };

    checkRectStageBoundaries = (position, draggingItem) => {
        const {gridSize} = this.state;
        const {x, y} = position;

        draggingItem.y = Math.round(y / gridSize) * gridSize - parseInt(draggingItem.stroke_width) / 2;
        draggingItem.x = Math.round(x / gridSize) * gridSize - parseInt(draggingItem.stroke_width) / 2;

        return draggingItem;
    };

    checkItemStageBoundaries = (position, draggingItem) => {
        const {x, y} = position;

        draggingItem.x = x;
        draggingItem.y = y;

        return draggingItem;
    };

    handleDragMove = () => {
        // console.log(`dragmove`);
    };

    /*  END MOVE  */

    /*  DRAG AND DROP  */

    handleDragItemStart = ({currentTarget}) => {
        const width = currentTarget.getAttribute(`data-item-width`);
        const height = currentTarget.getAttribute(`data-item-height`);
        const src = currentTarget.querySelector(`img`).src;
        const item_id = currentTarget.getAttribute(`item_id`);
        this.setState({selectedItem: {src, width, height, item_id}});
    };

    handleDropItem = e => {
        e.preventDefault();
        const canvas = e.target.getBoundingClientRect();

        const pos = {
            x: e.clientX - canvas.left,
            y: e.clientY - canvas.top
        };

        this.createItem(pos);
    };

    createItem = position => {
        const {
            items,
            selectedItem: {src, width, height, item_id},
            gridSize
        } = this.state;

        position.x = Math.round((position.x - parseFloat((width / 100) * gridSize) / 2) / gridSize) * gridSize;
        position.y = Math.round((position.y - parseFloat((height / 100) * gridSize) / 2) / gridSize) * gridSize;

        const image = new window.Image();
        image.src = src;
        image.crossOrigin = `Anonymous`;

        image.onload = () => {
            const item = {
                ...position,
                image,
                width,
                origWidth: width,
                height,
                origHeight: height,
                item_id,
                rotation: 0,
                index: items.length === 0 ? 0 : items.length
            };
            this.setState({items: [...items, item]}, this.generateLegend);
        };
    };

    onItemClick = (item, type) => this.setState({clickedItem: {...item, type}});

    handleUnClickItem = () => this.setState({clickedItem: null});

    /*  END DRAG AND DROP  */

    /* TRANSFORM */

    setTransformFunctions = () => {
        this.setState({
            stageMouseDown: this.handleClickTranformItem,
            stageMouseUp: () => false,
            stageMouseMove: () => false,
            handleDragStart: () => false,
            handleDragEnd: () => false,
            handleDragMove: () => false,
            isDraggable: false,
            selectedTool: `transform`,
            showWallToolInfo: false
        });
    };

    handleClickTranformItem = e => {
        if (e.target.nodeType !== `Stage`) {
            const selectedShapeName = e.target.parent.name();
            this.setState({selectedShapeName});
            this.setMovingFunctions();
        } else {
            this.setState({selectedShapeName: ``});
        }
    };

    handleSaveTransform = ({currentTarget}) => {
        const {items} = this.state;
        const index = parseInt(currentTarget.name().split(`-`)[1]);
        const filteredItems = items.filter(item => item.index !== index);
        const rotation = currentTarget.rotation();
        const x = currentTarget.x();
        const y = currentTarget.y();
        this.setState({items: [...filteredItems, {...items[index], rotation, x, y}]});
    };

    /* END TRANSFORM */

    /* ZOOM */

    handleZoom = e => {
        e.preventDefault();
        const scaleBy = 1.01;
        if (this.stage) {
            const oldScale = this.stage._stage.scaleX();

            const mousePointTo = {
                x: this.stage._stage.getPointerPosition().x / oldScale - this.stage._stage.x() / oldScale,
                y: this.stage._stage.getPointerPosition().y / oldScale - this.stage._stage.y() / oldScale
            };

            const newScale = e.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            this.stage._stage.scale({x: newScale, y: newScale});
            this.setState({stageScale: newScale});

            const newPos = {
                x: -(mousePointTo.x - this.stage._stage.getPointerPosition().x / newScale) * newScale,
                y: -(mousePointTo.y - this.stage._stage.getPointerPosition().y / newScale) * newScale
            };
            this.stage._stage.position(newPos);
            this.stage._stage.batchDraw();
        }
    };

    /* END ZOOM */

    /* DRAG STAGE */

    handleDragStage = () => {
        this.drawGrid();
    };

    /* END DRAG STAGE */

    /* STROKEWIDTH */

    changeStrokeWidth = ({target: {value}}, item) => {
        switch (item.type) {
            case `rect`: {
                const {rectangles} = this.state;
                const filteredRectangles = rectangles.filter(rect => rect.index !== item.index);
                item.stroke_width = value;
                this.setState({rectangles: [...filteredRectangles, item]});
                break;
            }
            case `line`: {
                const {walls} = this.state;
                const filteredWalls = walls.filter(wall => wall.index !== item.index);
                item.stroke_width = value;
                this.setState({walls: [...filteredWalls, item]});
                break;
            }
            default:
                break;
        }
    };

    /* END STROKEWIDTH */

    /* PDF */

    printCanvas = canvas => {
        const WinPrint = window.open(
            ``,
            ``,
            `left=0,top=0,width=${this.mmToPx(297)},height=${this.mmToPx(210)},toolbar=0,scrollbars=0,status=0`
        );

        WinPrint.document.write(sketchStyle);
        WinPrint.document.write(`<body></body>`);
        const clientInfo = document.querySelector(`.client-info`).cloneNode(true);
        const itemInfo = document.querySelector(`.items-info`).cloneNode(true);
        WinPrint.document.body.appendChild(clientInfo);
        WinPrint.document.body.appendChild(canvas);
        WinPrint.document.body.appendChild(itemInfo);
        WinPrint.document.close();

        // WinPrint.focus();
        // setTimeout(() => {
        //     WinPrint.print();
        //     WinPrint.close();
        // }, 500);
    };

    pdf = e => {
        // const {sketch} = this.state;
        e.preventDefault();
        // sketch.sketchWidth = sketch.sketchWidth / sketch.widthFactor;
        // sketch.sketchHeight = sketch.sketchHeight / sketch.heightFactor;

        // this.setState({sketch}, () => {
        // this.stage._stage.batchDraw();
        // this.filterSketch();
        document.querySelector(`.konvajs-content`).firstElementChild.classList.add(`hide`);
        html2canvas(document.querySelector(`.konvajs-content`), {
            allowTaint: true,
            useCORS: true,
            scale: 1
        }).then(canvas => {
            const div = document.createElement(`div`);
            div.appendChild(canvas);
            const imgData = canvas.toDataURL(`image/png`);

            const img = document.createElement(`img`);
            img.className = `sketch`;
            img.src = imgData;
            img.style.width = this.mmToPx(297) + `px`;
            img.style.height = this.mmToPx(210) + `px`;
            img.style.maxWidth = this.mmToPx(297) + `px`;
            img.style.maxHeight = this.mmToPx(210) + `px`;
            document.querySelector(`.konvajs-content`).firstElementChild.classList.remove(`hide`);

            // sketch.sketchWidth = sketch.sketchWidth * sketch.widthFactor;
            // sketch.sketchHeight = sketch.sketchHeight * sketch.heightFactor;

            // this.setState({sketch}, () => {
            //     this.stage._stage.batchDraw();
            //     this.filterSketch();
            // });
            this.printCanvas(img);
        });
        // });
    };

    selectLineColor = selectedLineColor => this.setState({selectedLineColor});

    generateLegend = () => {
        const {items} = this.state;
        async.mapSeries(
            items,
            (j, cb) => {
                fetch_item(j.item_id, ({item}) => {
                    cb(
                        null,
                        <li className="client-info-item client-info-item-logo" key={j.item_id + Math.random()}>
                            <p className="upper client-info-title">{item.title}</p>
                            <p className="item-info-item">{item.description}</p>
                            <img alt="" className="contain" src={item.image} />
                        </li>
                    );
                });
            },
            (err, results) => {
                this.setState({legend: results});
            }
        );
    };

    scaleBy = 1.01;
    stageZoom = e => {
        // e.evt.preventDefault();
        // const scaleBy = 1.01;
        // const stage = e.target.getStage();
        // const oldScale = stage.scaleX();
        // const mousePointTo = {
        //     x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        //     y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        // };
        // const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        // stage.scale({x: newScale, y: newScale});
        // this.setState({
        //     stageScale: newScale,
        //     stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        //     stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
        // });
    };

    render() {
        const {
            rendered,
            walls,
            stageMouseDown,
            stageMouseUp,
            stageMouseMove,
            selectedTool,
            rectangles,
            circles,
            items,
            isDraggable,
            handleDragStart,
            handleDragEnd,
            handleDragMove,
            selectedSketch,
            categories,
            clickedItem,
            selectedShapeName,
            showClientModal,
            sketch: {scale},
            sketch,
            showItems,
            showNotification,
            showWallToolInfo,
            lineType,
            selectedLineColor,
            texts,
            showTextModal,
            legend
        } = this.state;

        return (
            <div className="sketch-container">
                {showNotification && <Notification title="Sketch is opgeslagen!" type="Success" />}

                <aside className="dashboard-aside">
                    <Tool
                        handleToolClick={this.setMovingFunctions}
                        icon={<i className="fal fa-hand-pointer" />}
                        name="move"
                        selectedTool={selectedTool}
                    />
                    <Tool
                        handleToolClick={this.setWallFunctions}
                        icon={<i className="fal fa-pen" />}
                        name="line"
                        selectedTool={selectedTool}
                    />

                    <Tool
                        handleToolClick={this.setRectFunctions}
                        icon={<i className="fal fa-square" />}
                        name="rect"
                        selectedTool={selectedTool}
                    />
                    <Tool
                        handleToolClick={this.setTextFunctions}
                        icon={<i className="fal fa-font" />}
                        name="text"
                        selectedTool={selectedTool}
                    />
                    <Tool
                        handleToolClick={this.setEraseFunctions}
                        icon={<i className="fas fa-eraser" />}
                        name="eraser"
                        selectedTool={selectedTool}
                    />

                    <Tool
                        handleToolClick={this.setTransformFunctions}
                        icon={<i className="fal fa-arrows" />}
                        name="transform"
                        selectedTool={selectedTool}
                    />
                </aside>
                <header className="sketch-header flex-center space-between">
                    <div className="sketch-title">{selectedSketch.title}</div>
                    {showWallToolInfo && (
                        <ul className="flex-center tool-info">
                            <li className="flex-center">
                                <input
                                    checked={lineType === `9` ? true : false}
                                    id="9"
                                    name="lineType"
                                    onChange={this.onChangeRadio}
                                    type="radio"
                                    value="9"
                                />
                                <label htmlFor="9">Lijndikte: 9</label>
                            </li>
                            <li className="flex-center">
                                <input
                                    checked={lineType === `14` ? true : false}
                                    id="14"
                                    name="lineType"
                                    onChange={this.onChangeRadio}
                                    type="radio"
                                    value="14"
                                />
                                <label htmlFor="14">Lijndikte: 14</label>
                            </li>
                            <li className="flex-center">
                                <input
                                    checked={lineType === `help` ? true : false}
                                    id="help"
                                    name="lineType"
                                    onChange={this.onChangeRadio}
                                    type="radio"
                                    value="help"
                                />
                                <label htmlFor="help">Hulplijn</label>
                            </li>
                            <li className="flex-center">
                                <ul className="line-color-list flex-center">
                                    <li
                                        className={`line-color ${
                                            selectedLineColor === `grey` ? `line-color-selected` : ``
                                        } pointer`}
                                        onClick={() => this.selectLineColor(`grey`)}
                                        style={{backgroundColor: `grey`}}
                                    />
                                    <li
                                        className={`line-color ${
                                            selectedLineColor === `red` ? `line-color-selected` : ``
                                        } pointer`}
                                        onClick={() => this.selectLineColor(`red`)}
                                        style={{backgroundColor: `red`}}
                                    />
                                    <li
                                        className={`line-color ${
                                            selectedLineColor === `blue` ? `line-color-selected` : ``
                                        } pointer`}
                                        onClick={() => this.selectLineColor(`blue`)}
                                        style={{backgroundColor: `blue`}}
                                    />
                                    <li
                                        className={`line-color ${
                                            selectedLineColor === `yellow` ? `line-color-selected` : ``
                                        } pointer`}
                                        onClick={() => this.selectLineColor(`yellow`)}
                                        style={{backgroundColor: `yellow`}}
                                    />
                                </ul>
                            </li>
                        </ul>
                    )}
                    <div className="flex-center">
                        <div className="save-sketch pointer">
                            <i className="fal fa-info-square" onClick={this.toggleSidebar} />
                        </div>
                        <div className="save-sketch pointer">
                            <i className="fal fa-file-pdf" onClick={this.pdf} />
                        </div>
                        <div className="save-sketch pointer">
                            <i className="fal fa-save" onClick={() => this.handleSaveSketch()} />
                        </div>
                        <div className="save-sketch pointer">
                            <Link to="/">
                                <i className="fal fa-home" />
                            </Link>
                        </div>
                    </div>
                </header>
                <div className="sketch-holder">
                    <div
                        className="sketch-canvas-container"
                        onDragOver={e => e.preventDefault()}
                        onDrop={this.handleDropItem}
                        ref={node => (this.canvasContainer = node)}
                        style={{
                            minWidth: sketch.sketchWidth,
                            minHeight: sketch.sketchHeight,
                            maxWidth: sketch.sketchWidth,
                            maxHeight: sketch.sketchHeight
                        }}>
                        {rendered && (
                            <Stage
                                height={this.canvasContainer.offsetHeight}
                                onDragMove={this.onDragStage}
                                onDragStart={this.onDragStage}
                                onMouseDown={stageMouseDown}
                                onMouseMove={stageMouseMove}
                                onMouseUp={stageMouseUp}
                                onTouchEnd={stageMouseUp}
                                onTouchMove={stageMouseMove}
                                onTouchStart={stageMouseDown}
                                onWheel={this.stageZoom}
                                ref={node => (this.stage = node)}
                                scaleX={this.state.stageScale}
                                scaleY={this.state.stageScale}
                                width={this.canvasContainer.offsetWidth}
                                x={this.state.stageX}
                                y={this.state.stageY}>
                                <Layer>
                                    {this.drawGrid()}

                                    <Rect
                                        dash={[7, 7]}
                                        dashEnabled
                                        fill=""
                                        fillEnabled={false}
                                        height={sketch.canvasHeight}
                                        stroke="grey"
                                        strokeWidth={5}
                                        width={sketch.canvasWidth}
                                        x={sketch.sketchWidth / 2 - sketch.canvasWidth / 2}
                                        y={sketch.sketchHeight / 2 - sketch.canvasHeight / 2}
                                    />
                                </Layer>
                                <Layer ref={node => (this.drawCanvas = node)}>
                                    {texts.map((t, index) => (
                                        <Group
                                            draggable={isDraggable}
                                            key={index}
                                            name={`text-${index}`}
                                            onClick={selectedTool === `move` ? () => this.onItemClick(t, `text`) : () => false}
                                            onDragEnd={handleDragEnd}
                                            onDragMove={handleDragMove}
                                            onDragStart={handleDragStart}
                                            onTap={selectedTool === `move` ? () => this.onItemClick(t, `text`) : () => false}
                                            ref={node => (this[`rectangle${index}`] = node)}
                                            rotation={t.rotation}
                                            x={t.x}
                                            y={t.y}>
                                            <Text fill="green" fontSize={15} text={t.text} x={0} y={0} />
                                        </Group>
                                    ))}
                                    {rectangles.map((rect, index) => (
                                        <Group
                                            draggable={isDraggable}
                                            key={index}
                                            name={`rect-${index}`}
                                            onClick={selectedTool === `move` ? () => this.onItemClick(rect, `rect`) : () => false}
                                            onDragEnd={handleDragEnd}
                                            onDragMove={handleDragMove}
                                            onDragStart={handleDragStart}
                                            onTap={selectedTool === `move` ? () => this.onItemClick(rect, `rect`) : () => false}
                                            ref={node => (this[`rectangle${index}`] = node)}
                                            rotation={rect.rotation}
                                            x={rect.x}
                                            y={rect.y}>
                                            <Rect
                                                fill={rect.fill}
                                                fillEnabled={false}
                                                height={rect.height}
                                                opacity={rect.opacity}
                                                stroke={rect.stroke}
                                                strokeWidth={rect.stroke_width}
                                                width={rect.width}
                                                x={0}
                                                y={0}
                                            />
                                            <Text
                                                fill="green"
                                                fontSize={15}
                                                text={`${Math.abs((rect.width / this.px) * scale).toFixed(0)}`}
                                                x={rect.width / 2 - 15}
                                                y={rect.height < 0 ? 0 - 25 : 0 + 15}
                                            />
                                            <Text
                                                fill="green"
                                                fontSize={15}
                                                text={`${Math.abs((rect.height / this.px) * scale).toFixed(0)}`}
                                                x={rect.height < 0 ? rect.width + 15 : rect.width - 45}
                                                y={rect.height / 2}
                                            />
                                        </Group>
                                    ))}
                                    {walls.map((wall, index) => (
                                        <Group
                                            draggable={isDraggable}
                                            key={index}
                                            onClick={selectedTool === `move` ? () => this.onItemClick(wall, `line`) : () => false}
                                            onDragEnd={handleDragEnd}
                                            onDragMove={handleDragMove}
                                            onDragStart={handleDragStart}
                                            onTap={selectedTool === `move` ? () => this.onItemClick(wall, `line`) : () => false}
                                            rotation={wall.rotation}
                                            x={wall.x}
                                            y={wall.y}>
                                            {wall.dashEnabled ? (
                                                <Arrow
                                                    dash={[7, 7]}
                                                    dashEnabled={wall.dashEnabled ? true : false}
                                                    lineCap="butt"
                                                    opacity={wall.opacity}
                                                    pointerAtBeginning
                                                    points={[wall.beginX, wall.beginY, wall.endX, wall.endY]}
                                                    stroke={wall.color ? wall.color : `grey`}
                                                    // strokeWidth={this.mmToPx(wall.stroke_width)}
                                                    strokeWidth={2}
                                                    x={0}
                                                    y={0}
                                                />
                                            ) : (
                                                <Line
                                                    dash={[7, 7]}
                                                    dashEnabled={wall.dashEnabled ? true : false}
                                                    lineCap="butt"
                                                    opacity={wall.opacity}
                                                    points={[wall.beginX, wall.beginY, wall.endX, wall.endY]}
                                                    stroke={wall.color ? wall.color : `grey`}
                                                    // strokeWidth={this.mmToPx(wall.stroke_width)}
                                                    strokeWidth={2}
                                                    x={0}
                                                    y={0}
                                                />
                                            )}
                                            {this.generateWallLength(wall).text}
                                        </Group>
                                    ))}
                                    {circles.map((circle, index) => (
                                        <Ellipse
                                            draggable={isDraggable}
                                            key={index}
                                            onClick={
                                                selectedTool === `move` ? () => this.onItemClick(circle, `circle`) : () => false
                                            }
                                            onDragEnd={handleDragEnd}
                                            onDragMove={handleDragStart}
                                            onTap={
                                                selectedTool === `move` ? () => this.onItemClick(circle, `circle`) : () => false
                                            }
                                            rotation={circle.rotation}
                                            stroke={circle.stroke}
                                            strokeWidth={circle.stroke_width}
                                            x={circle.x}
                                            y={circle.y}
                                        />
                                    ))}
                                    {items.map((item, index) => (
                                        <Group
                                            draggable={isDraggable}
                                            key={index}
                                            name={`item-${index}`}
                                            onClick={selectedTool === `move` ? () => this.onItemClick(item, `item`) : () => false}
                                            onDragEnd={handleDragEnd}
                                            onDragMove={handleDragMove}
                                            onDragStart={handleDragStart}
                                            onTap={selectedTool === `move` ? () => this.onItemClick(item, `item`) : () => false}
                                            onTransformEnd={this.handleSaveTransform}
                                            ref={node => (this[`item${index}`] = node)}
                                            rotation={item.rotation}
                                            x={item.x}
                                            y={item.y}>
                                            <Image
                                                height={item.height / 3.92}
                                                image={item.image}
                                                width={item.width / 3.92}
                                                x={0}
                                                y={0}
                                            />
                                        </Group>
                                    ))}
                                    <TransformerComponent selectedShapeName={selectedShapeName} />
                                </Layer>
                            </Stage>
                        )}
                    </div>

                    <aside className="sketch-categories" style={{display: showItems ? `block` : `none`}}>
                        <div className="sketch-library">
                            <ul>
                                {categories.map(category => (
                                    <Category category={category} key={category._id} onDragItem={this.handleDragItemStart} />
                                ))}
                            </ul>
                        </div>
                        {clickedItem && (
                            <div className="item-information-container">
                                <p className="information-content-title">Informatie</p>
                                <div className="information-content">
                                    {clickedItem.type === `rect` && (
                                        <Fragment>
                                            <p className="information-content-item">Type: {clickedItem.type}</p>
                                            <p className="information-content-item">X: {clickedItem.x}</p>
                                            <p className="information-content-item">Y: {clickedItem.y}</p>
                                            <p className="information-content-item">Breedte: {clickedItem.width}</p>
                                            <p className="information-content-item">Lengte: {clickedItem.height}</p>
                                            {/* <p>
                                                Lijndikte:
                                                {` `}
                                                <input
                                                    name="strokeWidth"
                                                    onChange={e => this.changeStrokeWidth(e, clickedItem)}
                                                    type="number"
                                                    value={clickedItem.stroke_width}
                                                />
                                            </p> */}
                                        </Fragment>
                                    )}
                                    {clickedItem.type === `line` && (
                                        <Fragment>
                                            <p className="information-content-item">Type: {clickedItem.type}</p>
                                            <div className="flex-center">
                                                <div className="information-content-item">
                                                    X:
                                                    {` `}
                                                    {/* <input
                                                        onChange={e => this.changeWallPos(e, clickedItem, `x`)}
                                                        value={clickedItem.beginX}
                                                    /> */}
                                                    {clickedItem.beginX}
                                                </div>
                                                &nbsp;&nbsp;&nbsp;
                                                <div className="information-content-item">
                                                    Y:
                                                    {` `}
                                                    {/* <input
                                                        onChange={e => this.changeWallPos(e, clickedItem, `y`)}
                                                        value={clickedItem.beginY}
                                                    /> */}
                                                    {clickedItem.beginY}
                                                </div>
                                            </div>
                                            <p className="information-content-item">
                                                Lengte:
                                                {` `}
                                                <input onChange={e => this.changeWallPos(e, clickedItem, `length`)} />
                                            </p>

                                            {/* <Input
                                                name="strokeWidth"
                                                onChange={e => this.changeStrokeWidth(e, clickedItem)}
                                                title="Lijndikte"
                                                value={clickedItem.stroke_width}
                                            /> */}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                    </aside>
                </div>
                {showClientModal && (
                    <div
                        className="modal-wrapper"
                        onClick={e => handleHideModalAnywhere(e, `showClientModal`, this.modalContainer, this)}
                        ref={node => (this.modalContainer = node)}>
                        <form className="modal-content" onSubmit={this.pdf}>
                            <ModalTitle icon={<i className="fal fa-user" />} title="Pdf" />
                            <Textarea name="name" onChange={this.onChange} title="Project" />
                            <Textarea name="customer" onChange={this.onChange} title="Klant" />
                            <Input name="address" onChange={this.onChange} title="Adres" />
                            <Textarea name="installer" onChange={this.onChange} title="Installateur" />
                            <Input name="ordernr" onChange={this.onChange} title="Order nummer" />
                            <Button title="Pdf maken" />
                        </form>
                    </div>
                )}
                {showTextModal && (
                    <div
                        className="modal-wrapper"
                        onClick={e => handleHideModalAnywhere(e, `showTextModal`, this.modalContainer, this)}
                        ref={node => (this.modalContainer = node)}>
                        <form className="modal-content" onSubmit={e => e.preventDefault()}>
                            <ModalTitle icon={<i className="fal fa-user" />} title="Tekst" />
                            <Textarea name="textContent" onChange={this.onTextChange} title="Tekst" />
                            {/* <Button title="Aanmaken" /> */}
                        </form>
                    </div>
                )}
                <ul className="client-info flex-center space-between hide">
                    <li className="client-info-item">
                        <p className="upper client-info-title">Project</p>
                        <p>{sketch.project_name}</p>
                    </li>
                    <li className="client-info-item">
                        <p className="upper client-info-title">Klant</p>
                        <p>{sketch.customer}</p>
                    </li>
                    <li className="client-info-item">
                        <p className="upper client-info-title">Adres</p>
                        <p>{sketch.address}</p>
                    </li>
                    <li className="client-info-item">
                        <p className="upper client-info-title">Installateur</p>
                        <p>{sketch.installer}</p>
                    </li>
                    <li className="client-info-item">
                        <p className="upper client-info-title">Order</p>
                        <p>{sketch.ordernr}</p>
                    </li>
                    <li className="client-info-item client-info-item-logo">
                        <img alt="" className="contain" src={logo} />
                    </li>
                </ul>
                <ul className="client-info items-info hide">
                    {/* <li className="client-info-item">
                        <p className="upper client-info-title">Legende</p>
                    </li> */}
                    {legend}
                </ul>
            </div>
        );
    }
}

export default Sketch;
