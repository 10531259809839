import React from 'react';
import {objectOf, any, func} from 'prop-types';
import ActionButton from '../../../components/ActionButton/index';
import {handleShowModal} from '../../../lib/helpers';
import './settingCard.css';

const SettingsCard = ({environment, user, deleteUser, selectUser}) => {
    return (
        <li className="settings-card-holder flex-center space-between">
            <p className="settings-card-content">
                {user.firstname} {user.lastname}
            </p>
            <div className="flex-center">
                <ActionButton
                    icon={<i className="fal fa-pencil" />}
                    onClick={() => {
                        selectUser(user);
                        handleShowModal(`showUserModal`, environment);
                    }}
                />
                <ActionButton confirm icon={<i className="fal fa-trash-alt " />} onClick={() => deleteUser(user._id)} red />
            </div>
        </li>
    );
};

SettingsCard.propTypes = {
    user: objectOf(any).isRequired,
    environment: objectOf(any).isRequired,
    deleteUser: func.isRequired,
    selectUser: func.isRequired
};

export default SettingsCard;
