import React from 'react';
import {objectOf, any, func} from 'prop-types';
import './item.css';

const Item = ({item, onContextMenu}) => {
    return (
        <li className="item" id={item._id} onContextMenu={onContextMenu}>
            <div className="item-img">
                <img alt="" className="contain" src={item.image} />
            </div>
            <p className="item-title">{item.title}</p>
            <p className="item-desc">{item.description}</p>
        </li>
    );
};

Item.propTypes = {
    item: objectOf(any).isRequired,
    onContextMenu: func
};

Item.defaultProps = {
    onContextMenu: () => false
};

export default Item;
