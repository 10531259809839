import React, {Component} from 'react';
import {func, objectOf, any} from 'prop-types';
import './sketch.css';

import ContextMenu from '../../../components/ContextMenu';
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';

class Sketch extends Component {
    state = {
        showContextMenu: false
    };

    handleShowContextMenu = e => {
        e.preventDefault();
        const {showContextMenu} = this.state;
        this.setState({showContextMenu: !showContextMenu, position: {x: e.pageX, y: e.pageY}});
    };

    render() {
        const {showContextMenu, position} = this.state;
        const {handleSetSketch, sketch, deleteSketch} = this.props;

        return (
            <li className="template pointer" onClick={() => handleSetSketch(sketch)} onContextMenu={this.handleShowContextMenu}>
                <ContextMenu position={position} show={showContextMenu} showContextMenu={this.handleShowContextMenu}>
                    <ContextMenuItem
                        icon={<i className="fal fa-trash-alt" />}
                        onClick={() => deleteSketch(sketch._id)}
                        title="Verwijder"
                    />
                </ContextMenu>
                <i className="fal fa-file-edit" />
                <p>{sketch.title}</p>
                <p>
                    1:
                    {sketch.scale}
                </p>
            </li>
        );
    }
}

Sketch.propTypes = {
    handleSetSketch: func.isRequired,
    sketch: objectOf(any).isRequired,
    deleteSketch: func.isRequired
};

export default Sketch;
