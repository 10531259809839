import React, {Component} from 'react';
import {string} from 'prop-types';
import {render, unmountComponentAtNode} from 'react-dom';

// import ActionButton from '../ActionButton';

import './index.css';

class Notification extends Component {
    state = {};

    componentDidMount() {
        this.notification = document.createElement(`div`);
        this.notification.classList.add(`notification-wrapper`);
        this.notification.addEventListener(`click`, this.handleClickAway);
        document.body.appendChild(this.notification);
        this.renderNotification(this.props);
    }

    componentDidUpdate() {
        this.renderNotification(this.props);
    }

    componentWillUnmount() {
        this.notification.classList.add(`notification-willunmount`);
        setTimeout(() => {
            document.body.removeChild(this.notification);
            unmountComponentAtNode(this.notification);
        }, 250);
    }

    renderNotification = () => {
        const {type, title} = this.props;
        render(
            <div className={`notification pointer flex-center space-between ${type}`}>
                {(type === `normal` || type === `Normal`) && <i className="fas fa-info" />}
                {(type === `success` || type === `Success`) && <i className="fal fa-check" />}
                {(type === `warn` || type === `Warn`) && <i className="fas fa-exclamation" />}
                {(type === `error` || type === `Error`) && <i className="fal fa-times" />}
                <p className="notification-title">{title}</p>
            </div>,
            this.notification
        );
    };

    render() {
        return null;
    }
}

Notification.propTypes = {
    type: string,
    title: string
};

Notification.defaultProps = {
    type: `normal`,
    title: ``
};

export default Notification;
