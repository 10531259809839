import React, {Component} from 'react';
import ModalTitle from '../../../components/ModalTitle/index';
import Input from '../../../components/Input/index';
import {create_user, update_user_password} from '../../../lib/joossens_client';
import Button from '../../../components/Button/index';
import {func, objectOf, any} from 'prop-types';
import {handleHideModalAnywhere, handleShowModal} from '../../../lib/helpers';
class UserModal extends Component {
    state = {
        user: this.props.user
    };

    createUser = () => {
        const {
            user: {firstname, lastname, email, password, confirmPassword}
        } = this.state;

        const {fetchUsers} = this.props;

        if (password === confirmPassword) {
            create_user(firstname, lastname, email, password, ({message}) => {
                if (message === `Success`) {
                    const {environment} = this.props;
                    handleShowModal(`showUserModal`, environment);
                    fetchUsers();
                }
            });
        }
    };
    updateUser = () => {
        const {
            user: {firstname, lastname, email, password, confirmPassword, oldPassword, _id}
        } = this.state;

        update_user_password(
            _id,
            firstname,
            lastname,
            email,
            oldPassword,
            password,
            confirmPassword,
            ({message}) => message === `Success` && this.fetchUsers()
        );
    };

    onChange = ({target: {value, name}}) => {
        const {user} = this.state;
        this.setState({user: {...user, [name]: value}});
    };

    handleFormSubmit = e => {
        const {user} = this.props;
        e.preventDefault();
        if (user) {
            this.updateUser();
        } else {
            this.createUser();
        }
    };
    render() {
        const {environment, user: selectedUser} = this.props;

        return (
            <div
                className="modal-wrapper"
                onClick={e => handleHideModalAnywhere(e, `showUserModal`, this.modalContainer, environment)}
                ref={node => (this.modalContainer = node)}>
                <form className="modal-content user-modal" onSubmit={this.handleFormSubmit}>
                    <ModalTitle title="User" />
                    <div className="flex-center space-between">
                        <Input
                            name="firstname"
                            onChange={this.onChange}
                            rowSpan="double"
                            title="Voornaam"
                            value={selectedUser ? selectedUser.firstname : undefined}
                        />
                        <Input
                            name="lastname"
                            onChange={this.onChange}
                            rowSpan="double"
                            title="Achternaam"
                            value={selectedUser ? selectedUser.lastname : undefined}
                        />
                    </div>
                    <Input
                        name="email"
                        onChange={this.onChange}
                        title="Email"
                        value={selectedUser ? selectedUser.email : undefined}
                    />
                    {selectedUser && <Input name="oldPassword" onChange={this.onChange} title="old Password" type="password" />}
                    <Input name="password" onChange={this.onChange} title="New Password" type="password" />
                    <Input name="confirmPassword" onChange={this.onChange} title="Confirm Password" type="password" />
                    <div className="divide-line" />
                    <Button title={selectedUser ? `Pas aan` : `Maak aan`} />
                </form>
            </div>
        );
    }
}

UserModal.propTypes = {
    fetchUsers: func.isRequired,
    environment: objectOf(any).isRequired,
    user: objectOf(any)
};

UserModal.defaultProps = {
    user: null
};

export default UserModal;
