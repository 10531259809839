import React, {Component} from 'react';
import {fetch_users, delete_user} from '../../lib/joossens_client';
import './settings.css';
import SettingsCard from './SettingsCard/settingsCard';
import ActionButton from '../../components/ActionButton/index';
import Usermodal from './UserModal/userModal';
import {handleShowModal} from '../../lib/helpers';

class Settings extends Component {
    state = {
        users: [],
        showUserModal: false,
        selectedUser: null
    };
    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => fetch_users(data => data.message === `Success` && this.setState({users: data.users}));

    deleteUser = id => {
        delete_user(id, ({message}) => {
            if (message === `Success`) {
                const {users} = this.state;
                const filteredUsers = users.filter(user => user._id !== id);
                this.setState({users: filteredUsers});
            }
        });
    };

    selectUser = selectedUser => this.setState({selectedUser});

    render() {
        const {users, showUserModal, selectedUser} = this.state;
        return (
            <div className="dashboard-container">
                <div className="flex-center space-between">
                    <h2 className="dashboard-title">Users</h2>
                    <ActionButton
                        icon={<i className="fal fa-plus" />}
                        onClick={() => {
                            this.setState({selectedUser: null});
                            handleShowModal(`showUserModal`, this);
                        }}
                        text="Nieuwe user"
                    />
                </div>
                <ul className="user-list">
                    {users.map(user => (
                        <SettingsCard
                            deleteUser={this.deleteUser}
                            environment={this}
                            key={user._id}
                            selectUser={this.selectUser}
                            user={user}
                        />
                    ))}
                </ul>

                {showUserModal && <Usermodal environment={this} fetchUsers={this.fetchUsers} user={selectedUser} />}
            </div>
        );
    }
}

export default Settings;
