import React from 'react';
import {string, func} from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import Sketch from '../views/Sketch/sketch';
import ErrorPage from '../views/Error';
import Login from '../views/Login/login';
import NewSketch from '../views/NewSketch/newSketch';
import Dashboard from '../views/Dashboard/dashboard';
import Library from '../views/Library/library';
import Settings from '../views/Settings/settings';

const Routes = ({pathname, fetchUser}) => {
    return (
        <Switch>
            <Route exact path="/login" render={() => <Login fetchUser={fetchUser} />} />
            {pathname === `/login` && <Redirect to="/" />}
            <Route component={Sketch} exact path="/sketch" />
            <Route component={Dashboard} exact path="/" />
            <Route component={NewSketch} exact path="/new" />
            <Route component={Library} exact path="/library" />
            <Route component={Settings} exact path="/settings" />

            <Route component={ErrorPage} />
        </Switch>
    );
};

Routes.propTypes = {
    pathname: string.isRequired,
    fetchUser: func.isRequired
};

export default Routes;
