import 'whatwg-fetch';
import '@babel/polyfill';
import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './containers/app';
import SketchProvider from './contexts/sketchContext';

const init = () =>
    render(
        <Router>
            <SketchProvider>
                <App />
            </SketchProvider>
        </Router>,
        document.querySelector(`.react-mount`)
    );

init();
