import React, {Fragment} from 'react';
import {hot} from 'react-hot-loader';
import {objectOf, any} from 'prop-types';
import {withRouter} from 'react-router-dom';
import Routes from '../routes/routes';
import Login from '../views/Login/login';
import {Consumer} from '../contexts/sketchContext';
import NavBar from '../components/NavBar/navBar';

const App = ({location: {pathname}}) => {
    return (
        <Consumer>
            {({user, fetched, fetchUser}) =>
                user && fetched ? (
                    <Fragment>
                        {pathname !== `/login` && pathname !== `/sketch` && <NavBar pathname={pathname} />}
                        <Routes fetchUser={fetchUser} pathname={pathname} />
                    </Fragment>
                ) : fetched && !user ? (
                    <Login fetchUser={fetchUser} />
                ) : null
            }
        </Consumer>
    );
};

App.propTypes = {
    location: objectOf(any).isRequired
};

export default hot(module)(withRouter(App));
