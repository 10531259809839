import React, {Component} from 'react';
import {Transformer} from 'react-konva';

class TransformerComponent extends Component {
    componentDidMount() {
        this.checkNode();
    }

    componentDidUpdate() {
        this.checkNode();
    }

    componentDidCatch(error, info) {
        console.log(`error :`, error);
        console.log(`info :`, info);
    }

    checkNode() {
        const stage = this.transformer.getStage();
        const {selectedShapeName} = this.props;
        const selectedNode = stage.findOne(`.` + selectedShapeName);

        // if (selectedNode === this.transformer.node()) {
        //     return;
        // }
        if (selectedNode && selectedShapeName.search(`item-`) > -1) {
            const {children} = selectedNode;
            const rect_attrs = children[0].attrs;
            this.transformer.attachTo(selectedNode);
        } else {
            this.transformer.detach();
        }
        this.transformer.getLayer().batchDraw();
    }
    render() {
        return (
            <Transformer
                ref={node => (this.transformer = node)}
                resizeEnabled={false}
                rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315, 260]}
            />
        );
    }
}

export default TransformerComponent;
