import React, {Component} from 'react';
import {objectOf, any, func} from 'prop-types';
import {withRouter} from 'react-router-dom';

import Input from '../../components/Input';
import Button from '../../components/Button';

import './login.css';

import {login} from '../../lib/joossens_client';

class Login extends Component {
    state = {
        showError: false
    };

    onChange = ({target: {value, name}}) => this.setState({[name]: value});

    handleLogin = e => {
        e.preventDefault();
        const {username, password} = this.state;
        const {fetchUser} = this.props;
        login(username, password, data => {
            if (!data) {
                this.setState({showError: true});
            } else {
                localStorage.setItem(`joossens_account`, JSON.stringify(data));
                fetchUser(() => this.props.history.push(`/`));
            }
        });
    };

    render() {
        const {showError} = this.state;

        return (
            <div className="login-container">
                <div className="login">
                    <div className="configurator-titles">
                        <h1 className="upper">joossens</h1>
                        <h2 className="upper">configurator</h2>
                    </div>
                    <form className="login-form" onSubmit={this.handleLogin}>
                        <Input
                            errorText="Uw wachtwoord of gebruikersnaam is verkeerd."
                            name="username"
                            onChange={this.onChange}
                            placeholder="Gebruikersnaam"
                            showError={showError}
                            title="Gebruikersnaam"
                        />
                        <Input
                            errorText="Uw wachtwoord of gebruikersnaam is verkeerd."
                            name="password"
                            onChange={this.onChange}
                            placeholder="Paswoord"
                            showError={showError}
                            title="paswoord"
                            type="password"
                        />
                        <Button title="Inloggen" />
                    </form>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    history: objectOf(any).isRequired,
    fetchUser: func.isRequired
};

export default withRouter(Login);
