import React, {Component, Fragment} from 'react';
import {arrayOf, any, objectOf, func} from 'prop-types';
import './itemModal.css';
import {handleHideModalAnywhere, handleShowModal} from '../../../lib/helpers';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Switch from '../../../components/Switch';
import Textarea from '../../../components/Textarea';
import ModalTitle from '../../../components/ModalTitle';
import {create_category, create_item} from '../../../lib/joossens_client';

class ItemModal extends Component {
    state = {
        isSubCategory: false,
        isNewCategory: false,
        parentCategoryId: null,
        itemLength: 0,
        itemWidth: 0,
        categoryId: this.props.categories[0]._id
    };

    onChange = ({target: {value, name}}) => this.setState({[name]: value});

    onSwitch = (name, checked) => this.setState({[name]: checked, parentCategoryId: null});

    onChangeNumber = ({target: {value, name}}) => {
        value = value.replace(/^((?!^[0-9]*$).)*$/g, ``);
        this.setState({[name]: value});
    };

    createCategory = cb => {
        const {categoryName, categoryDescription, parentCategoryId} = this.state;

        create_category(categoryName, categoryDescription, parentCategoryId, ({message, category}) => {
            if (message === `Success`) {
                const {fetchLibrary, environment, fetchCategories} = this.props;

                fetchLibrary();
                fetchCategories();
                handleShowModal(`showItemModal`, environment);
                if (cb) {
                    cb(category._id);
                }
            }
        });
    };

    createItem = categoryId => {
        const {itemName, itemDescription, itemLength, itemWidth, file} = this.state;

        if (!categoryId) {
            categoryId = this.state.categoryId;
        }

        create_item(itemName, itemDescription, itemLength, itemWidth, categoryId, null, file, ({message}) => {
            if (message === `Success`) {
                const {fetchLibrary, fetchCategories, environment} = this.props;
                fetchLibrary();
                fetchCategories();
                handleShowModal(`showItemModal`, environment);
            }
        });
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const {isNewCategory} = this.state;
        if (isNewCategory) {
            this.createCategory(this.createItem);
        } else {
            this.createItem();
        }
    };

    previewMedia = ({target: {files}}) => {
        if (files) {
            const reader = new FileReader();

            reader.onload = () => {
                this.previewItem.src = reader.result;
                console.log(files[0]);
                this.setState({file: files[0]});
            };

            reader.readAsDataURL(files[0]);
        }
    };

    render() {
        const {categories, environment} = this.props;
        const {isSubCategory, isNewCategory, itemLength, itemWidth} = this.state;

        return (
            <div
                className="modal-wrapper"
                onClick={e => handleHideModalAnywhere(e, `showItemModal`, this.modalContainer, environment)}
                ref={node => (this.modalContainer = node)}>
                <form className="modal-content item-modal" onSubmit={this.handleSubmitForm}>
                    <ModalTitle title="Item" />
                    <div className="flex space-between">
                        <Input name="itemName" onChange={this.onChange} rowSpan="double" title="Item naam" />
                        <Textarea name="itemDescription" onChange={this.onChange} rowSpan="double" title="Item beschrijving" />
                    </div>
                    <div className="flex space-between">
                        <Input
                            name="itemLength"
                            onChange={this.onChangeNumber}
                            rowSpan="double"
                            title="Item lengte (mm)"
                            value={itemLength}
                        />
                        <Input
                            name="itemWidth"
                            onChange={this.onChangeNumber}
                            rowSpan="double"
                            title="Item breedte (mm)"
                            value={itemWidth}
                        />
                    </div>
                    <div className="file-input-container">
                        <label className="file-input" htmlFor="file-input">
                            <i className="fal fa-plus" />
                            <input
                                className="hide-file-input"
                                id="file-input"
                                name="media"
                                onChange={this.previewMedia}
                                type="file"
                            />
                            <div className="preview-img">
                                <img alt="" className="contain" ref={node => (this.previewItem = node)} src="" />
                            </div>
                        </label>
                    </div>
                    {!isNewCategory && (
                        <Select name="categoryId" onChange={this.onChange} title="Categorie">
                            {categories.map(category => (
                                <option key={category._id} value={category._id}>
                                    {category.title}
                                </option>
                            ))}
                        </Select>
                    )}
                    <div className="divide-line" />
                    <Switch checked={isNewCategory} name="isNewCategory" onSwitch={this.onSwitch} title="Nieuwe categorie" />
                    {isNewCategory && (
                        <Fragment>
                            <div className="flex space-between">
                                <Input name="categoryName" onChange={this.onChange} rowSpan="double" title="Categorie naam" />
                                <Textarea
                                    name="categoryDescription"
                                    onChange={this.onChange}
                                    rowSpan="double"
                                    title="Categorie beschrijving"
                                />
                            </div>
                            <div className="divide-line" />
                            <Switch checked={isSubCategory} name="isSubCategory" onSwitch={this.onSwitch} title="Sub categorie" />
                            {isSubCategory && (
                                <Select name="parentCategoryId" onChange={this.onChangeSelect} title="Hoofd categorie">
                                    {categories.map(category => (
                                        <option key={category._id} value={category._id}>
                                            {category.title}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </Fragment>
                    )}
                    <Button title="Maak aan" />
                </form>
            </div>
        );
    }
}

ItemModal.propTypes = {
    categories: arrayOf(any).isRequired,
    environment: objectOf(any).isRequired,
    fetchLibrary: func.isRequired,
    fetchCategories: func.isRequired
};

export default ItemModal;
