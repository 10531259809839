/*eslint-disable*/

const printCSS = `
    /* PRINT CSS */
        @media print {
            @page {
                margin: 0mm;
                size: auto;
            }
            html, body {
                // height: 100%;
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
            }
        }

     /* END PRINT CSS */
`;

const resetCSS = `
    /* RESET CSS */

        a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,
        body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,
        embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,
        hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,
        ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,
        summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            vertical-align: baseline
        }

        article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,
        nav,section {
            display: block
        }

        body {
            line-height: 1
        }

        ol,ul {
            list-style: none
        }

        blockquote,q {
            quotes: none
        }

        blockquote::after,blockquote::before,q::after,q::before {
            content: '';
            content: none
        }

        html {
            box-sizing: border-box;
            font-size: 62.5%;
        }

        *,::after,::before {
            box-sizing: inherit
        }

    /* END RESET CSS */
`;

const generalCSS = `
    /* GENERAL CSS */
        @font-face {
            font-family: 'gothicA';
            src: url('/assets/fonts/Alternate_Gothic_No2_BT.woff');
        }

        :root {
            --blue: #2c98f0;
            --grey: #f7f7f7;
            --green: #bccd28;
            --red: #f9423a;
            --darkgrey: #3c3d3a;
        }

        .content {
            display: flex !important;
            flex-direction: row !important;
        }

        .content>div {
            padding: 1rem;
        }

        html {
            font-family:gothicA, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Droid Sans', 'Helvetica Neue', sans-serif !important;
        }

        html, body {
            // height: 100%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
        }

        body {
            padding: 2rem 3rem;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        input,
        textarea,
        button,
        select {
            outline: none;
            resize: none;
        }

        .upper {
            text-transform: uppercase;
        }

        .pointer {
            cursor: pointer;
        }

        .flex-center {
            display: flex;
            align-items: center;
        }

        .space-between {
            justify-content: space-between;
        }

        // .hide {
        //     display: none !important;
        // }

        .no-scroll {
            overflow: hidden;
        }

        .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .right-align {
            text-align: right;
        }

        .left-align {
            text-align: left;
        }

        .contain {
            object-fit: contain;
            width: 100%;
        }

        .darkgrey {
            color: var(--darkgrey);
        }

        .bold {
            font-weight: bold;
        }

    /* END GENERAL CSS */
`;

const canvasCSS = `
    sketch {
        // width: 100%;
        // height: auto;
        max-height: 95vh;
        margin-top: auto;
    }

    .client-info {
        margin-bottom: 2rem;
    }

    .client-info-item {
        margin: 1rem 2rem;
    }

    .client-info-item-logo {
        width: 20rem;
    }

    .client-info-title {
        font-weight: bold;
        font-size: 2rem;
    }

    .item-info-item {
        margin: 1rem 0;
    }

    .items-info {
        display: flex;
        flex-wrap: wrap;
    }

`;

export const sketchStyle = `
    <style>

        ${printCSS}

        ${resetCSS}

        ${generalCSS}

        ${canvasCSS}

    </style>
`;


/*eslint-enable*/
